<script setup>
import AvatarComponent from "../../../components/general/profile/AvatarComponent.vue";
import {computed, onMounted, ref} from "vue";
import {useWordleStore} from "./wordleStore";

const emit = defineEmits(['ended']);

const wordleStore = useWordleStore();

const showIntro = ref(true);
const fadeOut = ref(false);

const currentUserId = computed(() => {
    return window.Laravel.user.id
});

onMounted(() => {
    setTimeout(() => {
        fadeOut.value = true;
        setTimeout(() => {
            showIntro.value = false;
            emit('ended');
        }, 500);
    }, 2500);
});
</script>

<template>
    <div class="wordle-intro" :class="{'fade-out': fadeOut}" v-if="showIntro">
            <div class="wordle-intro__user"
            >
                <div class="wordle-intro__user-avatar">
                    <avatar-component
                        :default-avatar="typeof wordleStore.playerOne.avatar === 'string' ? JSON.parse(wordleStore.playerOne.avatar) : wordleStore.playerOne.avatar"
                    />
                </div>
                <div class="wordle-intro__user-name">
                    {{ wordleStore.playerOne.username ?? wordleStore.playerOne.name }}
                </div>
            </div>
            <span>VS</span>
            <div class="wordle-intro__user"
            >
                <div class="wordle-intro__user-avatar">
                    <avatar-component
                        :default-avatar="typeof wordleStore.playerTwo.avatar === 'string' ? JSON.parse(wordleStore.playerTwo.avatar) : wordleStore.playerTwo.avatar"
                    />
                </div>
                <div class="wordle-intro__user-name">
                    {{ wordleStore.playerTwo.username ?? wordleStore.playerTwo.name }}
                </div>
            </div>
    </div>
</template>

<style scoped>
.wordle-intro {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 99;
    position: absolute;
    top: 0;
    opacity: 1;
    transition: opacity .5s;
    color: #fff;
}

.wordle-intro.fade-out {
    opacity: 0;
}

.wordle-intro__user {
    display: flex;
    flex-direction: column;
    position: relative;
}

.wordle-intro__user-avatar {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 3px solid #fff;
}

.wordle-intro__user-winner {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    box-shadow:
        0 0 60px 30px #fff,
        0 0 100px 60px #f0f,
        0 0 140px 90px #0ff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.wordle-intro__user.active .wordle-intro__user-avatar {
    border-color: #17a539;
}

.wordle-intro__user-avatar svg {
    width: 100%;
    height: 100%;
    z-index: 99;
}

.wordle-intro__user-name {
    font-size: .875rem;
    font-weight: 600;
    text-align: center;
    margin: .375rem 0;
}

</style>
