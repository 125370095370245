/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import VueLazyLoad from 'vue-lazyload'

import * as Sentry from "@sentry/vue";
import { createPinia, PiniaVuePlugin } from 'pinia'

function isInjectedCode(event) {
    const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

    if (!frames || frames.length === 0) return false;

    const firstFrame = frames[0];
    if (firstFrame.filename === '<anonymous>') {
        return true;
    }

    const lastFrame = frames[frames.length - 1];
    if (
        typeof lastFrame.filename === 'string' &&
        (lastFrame.filename === window.location.pathname ||
            (lastFrame.filename.startsWith(window.location.origin) &&
                // static file should not be considered as injected code. We use react-script currently, and all js-generated files are in this "static" directory.
                !lastFrame.filename.includes('/static/')))
    ) {
        return true;
    }

    return frames.some(
        (frame) =>
            typeof frame.filename === 'string' &&
            (frame.filename.startsWith('https://www.googletagmanager.com') ||
                frame.filename.startsWith('https://googleads.') ||
            frame.filename.startsWith('https://static-forms.klaviyo.com') ||
        frame.filename.startsWith('https://connect.facebook')),
    );
}

Sentry.init({
    Vue: Vue,
    beforeSend(event, hint) {
        if (isInjectedCode(event)) {
            return null;
        }
        return event;
    },
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    environment: process.env.MIX_SENTRY_ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/virtualescaping\.com\/api/, /^https:\/\/virtueleescape\.nl\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Install BootstrapVue
import { FormInputPlugin } from 'bootstrap-vue/esm/components/form-input'
import { FormSelectPlugin } from 'bootstrap-vue/esm/components/form-select'
import { FormGroupPlugin } from 'bootstrap-vue/esm/components/form-group'
import { InputGroupPlugin } from 'bootstrap-vue/esm/components/input-group'
import { FormCheckboxPlugin } from 'bootstrap-vue/esm/components/form-checkbox'
import { ButtonPlugin } from 'bootstrap-vue/esm/components/button'
import { SpinnerPlugin } from 'bootstrap-vue/esm/components/spinner'
import { OverlayPlugin } from 'bootstrap-vue/esm/components/overlay'
import { CardPlugin } from 'bootstrap-vue/esm/components/card'
import { CollapsePlugin } from 'bootstrap-vue/esm/components/collapse'
import { FormPlugin } from 'bootstrap-vue/esm/components/form'
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormGroupPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(ButtonPlugin)
Vue.use(SpinnerPlugin)
Vue.use(OverlayPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormPlugin)

Vue.use(VueLazyLoad)

import { Lang } from 'laravel-vue-lang'
Vue.use(Lang)

Vue.use(PiniaVuePlugin)

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    let currency;

    switch(window.locale.currencyLocale) {
        case 'en-US':
            currency = 'USD';
            break;
        case 'en-GB':
            currency = 'GBP';
            break;
        case 'en-CA':
            currency = 'CAD';
            break;
        case 'en-AU':
            currency = 'AUD';
            break;
        case 'en-AE':
            currency = 'AED';
            break;
        case 'en-NZ':
            currency = 'NZD';
            break;
        case 'en-SG':
            currency = 'SGD';
            break;
        case 'en-TH':
            currency = 'THB';
            break;
        case 'en-IN':
            currency = 'INR';
            break;
        default:
            currency = 'EUR';
            break;
    }

    var formatter = new Intl.NumberFormat(window.locale.currencyLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });

    return formatter.format(value);
});

Vue.filter('toPrice', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat(window.locale.currencyLocale, {
        minimumFractionDigits: 2
    });

    return formatter.format(value);
});

Vue.mixin({
    methods: {
        getLocaleCurrency: () => {
            let currency = 'EUR';
            switch(window.locale.currencyLocale) {
                case 'en-US':
                    currency = 'USD';
                    break;
                case 'en-GB':
                    currency = 'GBP';
                    break;
                case 'en-CA':
                    currency = 'CAD';
                    break;
                case 'en-AU':
                    currency = 'AUD';
                    break;
                case 'en-EU':
                    currency = 'EUR';
                    break;
                case 'en-NZ':
                    currency = 'NZD';
                    break;
                case 'en-SG':
                    currency = 'SGD';
                    break;
                case 'en-TH':
                    currency = 'THB';
                    break;
                case 'en-IN':
                    currency = 'INR';
                    break;
                case 'en-AE':
                    currency = 'AED';
                    break;
            }

            return currency;
        },
    }
});

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('rooms-component', require('./components/general/RoomsComponent.vue').default);
Vue.component('redeem-voucher-for-room', require('./components/general/rooms/RedeemVoucherForRoom.vue').default);

Vue.component('voucher-component', require('./components/general/VoucherComponent.vue').default);
Vue.component('highscore-component', require('./components/general/HighscoreComponent.vue').default);
Vue.component('country-switcher-component', require('./components/general/CountrySwitcherComponent.vue').default);
Vue.component('faq-component', require('./components/general/FaqComponent.vue').default);
Vue.component('reviews-component', require('./components/general/ReviewsComponent.vue').default);

Vue.component('products-carousel-component', require('./components/general/products/CarouselComponent.vue').default);
Vue.component('product-room-component', require('./components/general/products/ProductRoomComponent.vue').default);
Vue.component('product-purchase-component', require('./components/general/products/ProductPurchaseComponent.vue').default);

Vue.component('profile-editor', require('./components/general/profile/ProfileEditor.vue').default);
Vue.component('avatar-component', require('./components/general/profile/AvatarComponent.vue').default);

Vue.component('register-component', require('./components/general/auth/RegisterComponent.vue').default);

Vue.component('cart-component', require('./components/general/checkout/CartComponent.vue').default);

Vue.component('wordle-lobby', require('./games/free/wordle-versus/WordleLobby.vue').default);

const pinia = createPinia()

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    pinia
});

let scrollpos = window.scrollY;
const header = document.querySelector("nav");
if(header) {
    const header_height = header.offsetHeight;

    const add_class_on_scroll = () => header.classList.add("scrolled");
    const remove_class_on_scroll = () => header.classList.remove("scrolled");

    window.addEventListener('scroll', function () {
        scrollpos = window.scrollY;

        if (scrollpos >= header_height) {
            add_class_on_scroll()
        } else {
            remove_class_on_scroll()
        }
    }, {passive: true});
}

var fadeInElements = []

document.addEventListener("DOMContentLoaded", function(event) {
    fadeInElements = Array.from(document.getElementsByClassName('fade-in'))
    document.addEventListener('scroll', handleScroll)
    handleScroll()
});

function handleScroll(evt) {
    for (var i = 0; i < fadeInElements.length; i++) {
        var elem = fadeInElements[i]
        if (isElemVisible(elem)) {
            elem.style.opacity = '1'
            elem.style.transform = 'scale(1)'
            fadeInElements.splice(i, 1) // only allow it to run once
        }
    }
}

const isElemVisible = (el) => {
    var rect = el.getBoundingClientRect()
    var elemTop = rect.top + 200 // 200 = buffer
    var elemBottom = rect.bottom
    return elemTop < window.innerHeight && elemBottom >= 0
}
