<script setup>
import {useWordleStore} from "./wordleStore";
import AvatarComponent from "../../../components/general/profile/AvatarComponent.vue";
import {computed} from "vue";

const wordleStore = useWordleStore();

const winningWord = computed(() => {
    return wordleStore.previousGuesses.slice(-1)[0].word.toUpperCase();
});
</script>

<template>
<div class="wordle-result">
    <div class="wordle-result__users">
        <div class="wordle-result__user"
        >
            <div v-if="wordleStore.winner?.id === wordleStore.playerOne.id" class="wordle-result__user-winner"></div>
            <div class="wordle-result__user-avatar">
                <avatar-component
                    :default-avatar="typeof wordleStore.playerOne.avatar === 'string' ? JSON.parse(wordleStore.playerOne.avatar) : wordleStore.playerOne.avatar"
                />
            </div>
            <div class="wordle-result__user-name">
                {{ wordleStore.playerOne.username ?? wordleStore.playerOne.name }}
            </div>
        </div>
        <span>VS</span>
        <div class="wordle-result__user"
        >
            <div v-if="wordleStore.winner?.id === wordleStore.playerTwo.id" class="wordle-result__user-winner"></div>
            <div class="wordle-result__user-avatar">
                <avatar-component
                    :default-avatar="typeof wordleStore.playerTwo.avatar === 'string' ? JSON.parse(wordleStore.playerTwo.avatar) : wordleStore.playerTwo.avatar"
                />
            </div>
            <div class="wordle-result__user-name">
                {{ wordleStore.playerTwo.username ?? wordleStore.playerTwo.name }}
            </div>
        </div>
    </div>
    <div class="wordle-result__score">
        <div><strong>{{ wordleStore.winner?.username }}</strong> won with the word <strong>{{ winningWord }}</strong></div>
        <div class="wordle-result__score-button" @click="wordleStore.joinLobby()">New game</div>
    </div>
</div>
</template>

<style scoped>
.wordle-result {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 99;
    position: absolute;
    top: 0;
    opacity: 1;
    transition: opacity .5s;
    color: #fff;
}

.wordle-result__score {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background: #fff;
    border-radius: 1rem;
    color: #000;
    padding: 0.875rem 1.125rem;
}

.wordle-result__score-button {
    cursor: pointer;
}

.wordle-result__users {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.wordle-result__user {
    display: flex;
    flex-direction: column;
    position: relative;
}

.wordle-result__user-avatar {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 3px solid #fff;
}

.wordle-result__user-winner {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    box-shadow: 0 0 3rem 1.5rem #fff, 0 0 5.5rem 3rem #f0f, 0 0 8rem 5rem #0ff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.wordle-result__user.active .wordle-result__user-avatar {
    border-color: #17a539;
}

.wordle-result__user-avatar svg {
    width: 100%;
    height: 100%;
    z-index: 99;
}

.wordle-result__user-name {
    font-size: .875rem;
    font-weight: 600;
    text-align: center;
    margin: .375rem 0;
}
</style>
