<template>
    <div class="form">
        <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-email'}">
            <div class="form__control" :class="{ 'has-error': errors.email }">
                <div class="form__slot">
                    <div class="form-text-field__slot">
                        <label for="input-email" class="form-label" :class="{'form-label__active': activeField === 'input-email' || form.email.value}">
                            {{ __('account.email') }}
                        </label>
                        <input
                            required="required"
                            id="input-email"
                            type="email"
                            @focusin="setActiveField('input-email')"
                            @focusout="setActiveField(null)"
                            v-model="form.email.value"
                        >
                    </div>
                    <div class="form-text-field__border"></div>
                </div>
                <div class="form-messages" v-if="errors.email">
                    <div class="form-messages__message">
                        {{ errors.email[0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-fullname'}">
            <div class="form__control" :class="{ 'has-error': errors.name }">
                <div class="form__slot">
                    <div class="form-text-field__slot">
                        <label for="input-fullname" class="form-label" :class="{'form-label__active': activeField === 'input-fullname' || form.fullname.value}">
                            {{ __('account.name') }}
                        </label>
                        <input
                            required="required"
                            id="input-fullname"
                            type="text"
                            @focusin="setActiveField('input-fullname')"
                            @focusout="setActiveField(null)"
                            v-model="form.fullname.value"
                        >
                    </div>
                    <div class="form-text-field__border"></div>
                </div>
                <div class="form-messages" v-if="errors.name">
                    <div class="form-messages__message">
                        {{ errors.name[0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-password'}">
                    <div class="form__control" :class="{ 'has-error': errors.password }">
                        <div class="form__slot">
                            <div class="form-text-field__slot">
                                <label for="input-password" class="form-label" :class="{'form-label__active': activeField === 'input-password' || form.password.value}">
                                    {{ __('account.password') }}
                                </label>
                                <input
                                    required="required"
                                    id="input-password"
                                    type="password"
                                    @focusin="setActiveField('input-password')"
                                    @focusout="setActiveField(null)"
                                    v-model="form.password.value"
                                >
                            </div>
                            <div class="form-text-field__border"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-passwordconfirm'}">
                    <div class="form__control">
                        <div class="form__slot">
                            <div class="form-text-field__slot">
                                <label for="input-passwordconfirm" class="form-label" :class="{'form-label__active': activeField === 'input-passwordconfirm' || form.passwordconfirm.value}">
                                    {{ __('account.password.confirm') }}
                                </label>
                                <input
                                    required="required"
                                    id="input-passwordconfirm"
                                    type="password"
                                    @focusin="setActiveField('input-passwordconfirm')"
                                    @focusout="setActiveField(null)"
                                    v-model="form.passwordconfirm.value"
                                >
                            </div>
                            <div class="form-text-field__border"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" :class="{ 'has-error': errors.password }">
                <div class="form-messages form-messages-wide" v-if="errors.password">
                    <div class="form-messages__message">
                        {{ errors.password[0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="form-checkbox mb-2" :class="{'form-checkbox__active': form.newsletter.value}">
            <div class="form__control">
                <div class="form__slot">
                    <div class="form-checkbox__slot">
                        <span aria-hidden="true" class="form-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="form-icon__svg">
                                <path d="M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" v-if="!form.newsletter.value"></path>
                                <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z" v-if="form.newsletter.value"></path>
                            </svg>
                        </span>
                        <input aria-checked="true" id="input-newsletter" role="checkbox" type="checkbox" v-model="form.newsletter.value">
                        <div class="form-checkbox__ripple"></div>
                    </div>
                    <label for="input-newsletter" class="v-label theme--light" style="left: 0px; right: auto; position: relative;">
                        {{ __('checkout.newsletter') }}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-7 d-flex align-items-center mb-2 mb-md-0 justify-content-center justify-content-md-start">
                {{ __('checkout.account.already') }} <a :href="loginRoute" class="ml-1">{{ __('checkout.login') }}</a>
            </div>
            <div class="col-12 col-md-5 text-center text-md-right">
                <button type="submit" class="btn btn-primary d-inline-flex align-items-center w-100 text-center justify-content-center" @click="register">
                    <span v-if="loading"><b-spinner small label="Loading..."></b-spinner></span><span v-if="!loading">{{  __('Account aanmaken') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterComponent",
    props: ['loginRoute'],
    data() {
        return {
            activeField: null,
            loading: false,
            form: {
                email: {
                    value: null
                },
                newsletter: {
                    value: false
                },
                fullname: {
                    value: null
                },
                password: {
                    value: null
                },
                passwordconfirm: {
                    value: null
                }
            },
            errors: {},
        }
    },
    computed: {
        email() {
            return this.form.email.value;
        },
        name() {
            return this.form.fullname.value;
        },
        password() {
            return this.form.password.value;
        }
    },
    watch: {
        email() {
            this.errors.email = null;
        },
        name() {
            this.errors.name = null;
        },
        password() {
            this.errors.password = null;
        },
    },
    methods: {
        setActiveField(field) {
            this.activeField = field;
        },
        register()
        {
            this.loading = true;

            axios.post('/register', {
                name: this.form.fullname.value,
                email: this.form.email.value,
                password: this.form.password.value,
                password_confirmation: this.form.passwordconfirm.value,
                newsletter: this.form.newsletter.value
            })
            .then(response => {
                window.location.href = response.data.returnUrl;
                this.loading = false;
                this.errors = {};
            })
            .catch(response => {
                this.loading = false;
                this.errors = {};
                this.errors = response.response.data.errors || {}
            });
        }
    }
}
</script>

<style scoped>

</style>
