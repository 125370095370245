<template>
    <div>
        <div class="form">
            <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-fullname'}">
                <div class="form__control" :class="{ 'has-error': errors.name }">
                    <div class="form__slot">
                        <div class="form-text-field__slot">
                            <label for="input-fullname" class="form-label" :class="{'form-label__active': activeField === 'input-fullname' || form.fullname.value}">
                                {{ __('account.name') }}
                            </label>
                            <input
                                required="required"
                                id="input-fullname"
                                type="text"
                                @focusin="setActiveField('input-fullname')"
                                @focusout="setActiveField(null)"
                                v-model="form.fullname.value"
                            >
                        </div>
                        <div class="form-text-field__border"></div>
                    </div>
                    <div class="form-messages" v-if="errors.name">
                        <div class="form-messages__message">
                            {{ errors.name[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-username'}">
                <div class="form__control" :class="{ 'has-error': errors.username }">
                    <div class="form__slot">
                        <div class="form-text-field__slot">
                            <label for="input-username" class="form-label" :class="{'form-label__active': activeField === 'input-username' || form.username.value}">
                                {{ __('account.username') }}
                            </label>
                            <input
                                required="required"
                                id="input-username"
                                type="text"
                                @focusin="setActiveField('input-username')"
                                @focusout="setActiveField(null)"
                                v-model="form.username.value"
                            >
                        </div>
                        <div class="form-text-field__border"></div>
                    </div>
                    <div class="form-messages" v-if="errors.username">
                        <div class="form-messages__message">
                            {{ errors.username[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-text-field" :class="{'form-text-field__active': activeField === 'input-email'}">
                <div class="form__control" :class="{ 'has-error': errors.email }">
                    <div class="form__slot">
                        <div class="form-text-field__slot">
                            <label for="input-email" class="form-label" :class="{'form-label__active': activeField === 'input-email' || form.email.value}">
                                {{ __('account.email') }}
                            </label>
                            <input
                                required="required"
                                id="input-email"
                                type="email"
                                @focusin="setActiveField('input-email')"
                                @focusout="setActiveField(null)"
                                v-model="form.email.value"
                                disabled
                            >
                        </div>
                        <div class="form-text-field__border"></div>
                    </div>
                    <div class="form-messages" v-if="errors.email">
                        <div class="form-messages__message">
                            {{ errors.email[0] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="pl-3">
                    <button type="submit" class="btn btn-primary d-inline-flex align-items-center text-center justify-content-center" @click="save">
                        <span v-if="loading"><b-spinner small label="Loading..."></b-spinner></span><span v-if="!loading">{{  __('account.profile.save') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfileDetails",
    props: ['user'],
    data() {
        return {
            activeField: null,
            loading: false,
            form: {
                email: {
                    value: this.user.email
                },
                username: {
                    value: this.user.username
                },
                fullname: {
                    value: this.user.name
                }
            },
            errors: {},
        }
    },
    computed: {
        email() {
            return this.form.email.value;
        },
        name() {
            return this.form.fullname.value;
        },
        username() {
            return this.form.username.value;
        }
    },
    watch: {
        email() {
            this.errors.email = null;
        },
        name() {
            this.errors.name = null;
        },
        username() {
            this.errors.username = null;
        },
    },
    methods: {
        setActiveField(field) {
            this.activeField = field;
        },
        save() {
            axios.post('/profile/', {
                name: this.form.fullname.value,
                username: this.form.username.value
            }).then(response => {
                this.loading = false;
                this.errors = {};
            }).catch(response => {
                this.loading = false;
                this.errors = {};
                this.errors = response.response.data.errors || {}
            });
        }
    }
}
</script>

<style scoped>

</style>
