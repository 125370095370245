<template>
    <div class="escaperoom__purchase" v-if="product.active">
        <div class="row">
            <div class="col-12 text-center mb-2">
                <b-form-input id="sb-inline" v-model.number="amount" min="1" max="500" type="number" pattern="\d*" inline number @keydown="filterKey" style="width: 4rem; text-align: center; margin: 0 auto;"></b-form-input>
            </div>
            <div class="col-12 text-center price">
                <span>
                    <span class="price-sale" v-if="product.locale_price.price < product.locale_price.price_original">{{ product.locale_price.locale.currency_symbol }}{{ product.locale_price.price_original * amount | toPrice }}</span>
                    <span class="price-default"><strong>{{ product.locale_price.locale.currency_symbol }}{{ (product.locale_price.price * amount) | toPrice }}</strong></span>
                </span>
                <strong>
                    <span v-if="amount === 1">
                        {{ __('per groep') }}
                    </span>
                    <span v-if="amount > 1">
                        {{ __('voor') }} {{ amount }} {{ __('groepen') }}
                    </span>
                </strong>
                <br />
                <a @click="buyNow()" class="escaperoom__purchase-btn" :class="{'clicked': buyingItem}">
                    <span class="escaperoom__purchase-btn-add-to-cart">{{ purchaseVoucher }}</span>
                    <span class="escaperoom__purchase-btn-adding"><b-spinner small label="Loading..."></b-spinner></span>
                    <div class="escaperoom__purchase-btn-cart">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                    </div>
                    <div class="escaperoom__purchase-btn-square">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                        </svg>
                    </div>
                </a>
                <a @click="addToCart()" class="escaperoom__purchase-btn escaperoom__purchase-btn-secondary" :class="{'clicked': addingToCart}">
                    <span class="escaperoom__purchase-btn-add-to-cart">{{ __('checkout.add-to-cart') }}</span>
                    <span class="escaperoom__purchase-btn-adding"><b-spinner small label="Loading..."></b-spinner></span>
                    <div class="escaperoom__purchase-btn-cart">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                    </div>
                    <div class="escaperoom__purchase-btn-square">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                        </svg>
                    </div>
                </a>
            </div>
            <div class="fast-delivery">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" version="1.1" viewBox="-22 0 134 134.06032" width="16px">
                    <g id="surface1">
                        <path d="M 23.347656 134.058594 C 8.445312 84.953125 39.933594 67.023438 39.933594 67.023438 C 37.730469 93.226562 52.621094 113.640625 52.621094 113.640625 C 58.097656 111.988281 68.550781 104.265625 68.550781 104.265625 C 68.550781 113.640625 63.035156 134.046875 63.035156 134.046875 C 63.035156 134.046875 82.34375 119.117188 88.421875 94.320312 C 94.492188 69.523438 76.859375 44.628906 76.859375 44.628906 C 77.921875 62.179688 71.984375 79.441406 60.351562 92.628906 C 60.933594 91.957031 61.421875 91.210938 61.796875 90.402344 C 63.886719 86.222656 67.242188 75.359375 65.277344 50.203125 C 62.511719 14.890625 30.515625 0 30.515625 0 C 33.273438 21.515625 25.003906 26.472656 5.632812 67.3125 C -13.738281 108.144531 23.347656 134.058594 23.347656 134.058594 Z M 23.347656 134.058594 " style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;"></path>
                    </g>
                </svg>
                {{ __('checkout.fast-delivery') }}
            </div>
            <div class="product-usps">
                <ul>
                    <li><img src="https://dgb3ryo0lspid.cloudfront.net/images/reviews/review-icon.png" alt="Reviews Icon"> <span v-html="__('rooms.usps.one')"></span></li>
                    <li><img src="https://dgb3ryo0lspid.cloudfront.net/images/reviews/best-overall-digital-game-award.png" alt="Best Online Escape Room Game Award"> {{ __('rooms.usps.two') }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductPurchaseComponent",
    props: ['product', 'room', 'eventTime'],
    data() {
        return {
            amount: 1,
            globalDiscountPercentage: window.globalDiscount.percentage,
            addingToCart: false,
            buyingItem: false
        }
    },
    computed: {
        purchaseVoucher() {
            if(this.amount === 1) {
                return this.__('Voucher kopen');
            } else {
                return this.__('Vouchers kopen');
            }
        }
    },
    watch: {
        amount(value) {
            this.amount = Math.max(1, value);
        }
    },
    methods: {
        addToCart()
        {
            if(!this.addingToCart && !this.buyingItem) {
                this.addToCartEvent();
                this.addingToCart = true;
                this.purchase(true);
            }
        },
        buyNow()
        {
            if(!this.addingToCart && !this.buyingItem) {
                this.addToCartEvent();
                this.buyingItem = true;
                this.purchase(false);
            }
        },
        addToCartEvent() {
            if(window.env !== 'local') {
                var productId = this.product.id;
                if(window.locale.language === 'nl') {
                    productId = productId + '-nl';
                }

                //Take 26% off the price, because of the 21% VAT and 5% transaction costs
                let price = this.product.locale_price.price - (this.product.locale_price.price / 100 * 26);

                fbq('track', 'AddToCart', {
                    content_name: this.product.name,
                    contents: [{'id': productId, 'quantity': this.amount, 'item_price': this.product.locale_price.price}],
                    content_type: 'product',
                    value: price * this.amount,
                    currency: this.product.locale_price.locale.currency_code,
                }, {eventID: 'event.addtocart.' + this.eventTime});

                dataLayer.push({
                    'event': 'add_to_cart',
                    'ecommerce': {
                        'items': [{
                            'item_id': productId,
                            'item_name': this.product.name,
                            'price': price,
                            'currency': this.product.locale_price.locale.currency_code,
                            'quantity': this.amount
                        }]
                    }
                });
            }
        },
        purchase(addToCart = false) {
            axios.post('/cart/add-product-as-item', {
                product: this.product,
                amount: this.amount,
                eventtime: this.eventTime,
            })
                .then((response) => {
                    if (response.data.redirect) {
                        if(addToCart === false) {
                            window.location = response.data.redirect;
                        } else {
                            if(this.$lang().locale === 'nl') {
                                window.location = '/winkelmand';
                            } else {
                                window.location = '/cart';
                            }
                        }
                    }
                });
        },
        filterKey(e){
            const key = e.key;

            if (['.', ',', 'e'].includes(key)) {
                return e.preventDefault();
            }
        },
    },
}
</script>

<style scoped>

</style>
