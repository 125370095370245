import { defineStore } from 'pinia'
import Echo from "laravel-echo"

export const useWordleStore = defineStore('wordle', {
    state: () => (
        {
            game: null,
            timer: null,
            timeLeft: 30,
            timerRunning: false,
            currentUserId: Laravel.user.id,
            submitting: false,
            guessError: false,
            previousGuesses: [],
            myTurn: false,
            correctLetters: [],
            closeLetters: [],
            wrongLetters: [],
            gameOver: false,
            winner: null
        }
    ),
    getters: {
        playerOne() {
            return this.game.player1 ?? JSON.parse(this.game.bot);
        },
        playerTwo() {
            return this.game.player2 ?? JSON.parse(this.game.bot);
        },
        currentUserId() {
            return window.Laravel.user.id;
        },
        currentUser() {
            return this.playerOne.id === this.currentUserId ? this.playerOne : this.playerTwo;
        },
        opponent() {
            return this.playerOne.id === this.currentUserId ? this.playerTwo : this.playerOne;
        }
    },
    actions: {
        joinLobby() {
            this.gameOver = false;
            this.winner = null;
            this.game = null;
            this.timeLeft = 30;
            this.previousGuesses = [];
            this.correctLetters = [];
            this.closeLetters = [];
            this.wrongLetters = [];

            const echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                forceTLS: true
            });

            echo.join("wordle")
                .here(users => {
                    this.users = users;

                    if(users.length > 1) {
                        this.inviteOtherUser();
                    } else {
                        this.botTimeout = setTimeout(() => {
                            if (!this.joiningGame && !this.game && !this.invitedUserId) {
                                this.joinGame({user: {id: null}}).then(() => {
                                    echo.leaveChannel("wordle");
                                });
                            }
                        }, 2000);
                    }
                }).leaving(user => {
                if(user.id === this.invitedUserId) {
                    this.invitedUserId = null;
                }
            });

            echo.private('wordle.invitation.' + Laravel.user.id)
                .listen('GameInvitation', (event) => {
                    if((!this.invitedUserId && !this.joiningGame && !this.game) || this.invitedUserId === event.user.id) {
                        this.joinGame(event);
                    }
                });
        },
        async joinGame(event) {
            axios.post('/api/v1/games/wordle-versus/join',
                {
                    user_id: Laravel.user.id,
                    other_user_id: event.user.id
                }).then((response) => {
                    this.game = response.data.game;
                    return Promise.resolve();
            });
        },
        startGame()
        {
            this.startTimer();

            if(this.game.bot && this.playerTwo.id === this.currentUserId) {
                this.submitGuess('', true);
            } else if(this.playerTwo === this.currentUserId) {
                this.myTurn = false;
            } else {
                this.myTurn = true;
            }
        },
        startTimer()
        {
            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timerRunning = true;
                    this.timeLeft--;
                } else {
                    this.stopTimer();
                    if (this.myTurn && !this.submitting) {
                        this.submitGuess('', true);
                    }
                }
            }, 1000);
        },
        resetTimer()
        {
            this.timerRunning = false;
            this.timeLeft = 30;
            this.startTimer();
        },
        stopTimer()
        {
            this.timerRunning = false;
            clearInterval(this.timer);
        },
        async submitGuess(guess, force = false)
        {
            return new Promise((resolve, reject) => {
                if (this.submitting && !force) {
                    return;
                } else if (force) {
                    guess = "";
                }

                this.submitting = true;
                axios.post("/api/v1/games/wordle-versus/guess",
                    {
                        game_id: this.game.id,
                        guess: guess,
                        previous_guesses: this.previousGuesses,
                        force: force
                    }
                ).then(response => {
                    this.stopTimer();
                    this.myTurn = false;
                    this.addGuess(response.data.result, this.currentUser);
                    if(!this.gameOver) {
                        this.resetTimer();
                        if (response.data.botGuess) {
                            this.playBot(response.data.botGuess);
                        }
                    }

                    resolve();
                }).catch(() => {
                    this.guessError = true;

                    reject();
                }).finally(() => {
                    setTimeout(() => {
                        this.submitting = false;
                        this.guessError = false;
                    }, 300);
                });
            });
        },
        addGuess(guess, player)
        {
            if(guess.word.length === 5) {
                this.correctLetters = Array.from(new Set(this.correctLetters.concat(guess.correctLetters.filter(letter => letter !== null))));
                this.closeLetters = Array.from(new Set(this.closeLetters.concat(guess.closeLetters.filter(letter => letter !== null))));
                const wrong = [];

                const wordLetters = guess.word.split('');
                wordLetters.forEach(letter => {
                    if (!this.correctLetters.includes(letter) && !this.closeLetters.includes(letter)) {
                        wrong.push(letter);
                    }
                });

                this.wrongLetters = Array.from(new Set(this.wrongLetters.concat(wrong)));

                this.previousGuesses.push(guess);

                if (guess.correct) {
                    this.gameOver = true;
                    this.winner = player;
                    this.stopTimer();
                }

                setTimeout(() => {
                    this.scrollToBottom();
                }, 100);
            }
        },
        scrollToBottom()
        {
            const guessesContainer = document.querySelector('.guesses-container');
            let timeOut = 250;

            if(guessesContainer.scrollHeight > 0) {
                timeOut = 0;
            }

            setTimeout(() => {
                guessesContainer.scroll({
                    top: guessesContainer.scrollHeight,
                    behavior: 'smooth'
                });
            }, timeOut);
        },
        playBot(guess)
        {
            let delay = Math.floor(Math.random() * (21000 - 8000 + 1)) + 8000;
            Math.random() < 0.5 ? delay = Math.floor(Math.random() * (8000 - 3000 + 1)) + 3000 : delay;

            setTimeout(() => {
                this.stopTimer();
                this.myTurn = true;
                this.addGuess(guess, this.opponent);

                if(!this.gameOver) {
                    this.resetTimer();
                }
            }, delay);
        }
    }
})
