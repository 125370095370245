import { render, staticRenderFns } from "./HighscoreComponent.vue?vue&type=template&id=5d373a68&scoped=true"
import script from "./HighscoreComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./HighscoreComponent.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d373a68",
  null
  
)

export default component.exports