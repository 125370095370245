<template>
    <div class="accordion py-4" role="tablist">
        <h2 class="mb-4">{{ __('Vragen en antwoorden') }}</h2>
        <div class="faq box-card box-card__shadow p-0">
            <div class="faq-title faq-title-first" v-b-toggle.accordion-10>{{ __('general.faq.question-1') }}</div>
            <b-collapse id="accordion-10" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-1') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-1>{{ __('general.faq.question-2') }}</div>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-2') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-2>{{ __('general.faq.question-3') }}</div>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-3') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-3>{{ __('Vanaf welke leeftijd raden jullie aan om te spelen?') }}</div>
            <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-4') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-4>{{ __('Met hoeveel mensen kunnen we tegelijk spelen?') }}</div>
            <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Op deze online escape rooms zit een limiet van zes apparaten. Dit betekent dat je vanaf 6 apparaten tegelijk samen kan spelen. Met hoeveel personen je speelt is dus helemaal aan jezelf, maar de leukste reacties kregen we uit teams met 3 t/m 5 personen.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-5>{{ __('Heb ik een programma als skype of teams nodig om te communiceren?') }}</div>
            <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Nee, wij hebben een ingebouwd communicatie schermpje, waarmee je via de webcam of microfoon met je teamgenoten kunt communiceren. Het is daarnaast ook mogelijk voor de mensen zonder microfoon om de chatfunctie te gebruiken.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-6>{{ __('Hoe werkt het online samen puzzels en raadsels oplossen?') }}</div>
            <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Bij onze online escape room is het zo gemaakt dat je samen moet communiceren om te vorderen in het spel. Communicatie is hierbij erg belangrijk, maar we hebben het iets makkelijker voor jullie gemaakt. Wanneer één iemand een puzzel oplost krijgen de andere teamgenoten hier gelijk een live melding van en zal op hun scherm ook de oplossing zijn verwerkt. Je hoeft door deze techniek niet allemaal apart de oplossing door te voeren. Je werkt hier dus echt tegelijkertijd samen naar het einddoel toe.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-7>{{ __('Hoe lang duurt deze online escape room?') }}</div>
            <b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Onze escape rooms hebben geen tijdslimiet. Expeditie Noordpool duurt gemiddeld 1,5 uur en Ondergrondse Moord duurt ongeveer 75 minuten. Het gebruik van hints kan erbij helpen om er sneller doorheen te komen, maar of dat net zo leuk is, is een tweede. De snelste teams worden vervolgens weergegeven in een highscore lijst.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-8>{{ __('Hoe lang is de voucher geldig?') }}</div>
            <b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Wanneer je voucher via onze website is gekocht, dan zit er geen verloopdatum op.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-9>{{ __('Hoe nodig ik mensen uit voor de escaperoom?') }}</div>
            <b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('Wanneer je de voucher gebruikt, wordt een team aangemaakt en word jij aangewezen als teamleider. Vervolgens kunnen de mensen waar je samen mee wilt spelen dezelfde vouchercode gebruiken. Ze worden dan automatisch in jouw team geplaatst.') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-11>{{ __('general.faq.question-11') }}</div>
            <b-collapse id="accordion-11" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-11') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-12>{{ __('general.faq.question-12') }}</div>
            <b-collapse id="accordion-12" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-12') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-13>{{ __('general.faq.question-13') }}</div>
            <b-collapse id="accordion-13" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-13') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-14>{{ __('general.faq.question-14') }}</div>
            <b-collapse id="accordion-14" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-14') }}
                </div>
            </b-collapse>
            <div class="faq-title" v-b-toggle.accordion-15>{{ __('general.faq.question-15') }}</div>
            <b-collapse id="accordion-15" visible accordion="my-accordion" role="tabpanel">
                <div class="faq-answer">
                    {{ __('general.faq.answer-15') }}
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FaqComponent"
    }
</script>

<style scoped>

</style>
