<script setup>
import avatarBackgrounds from "../../config/avatar/backgrounds";
import {computed, onMounted} from "vue";

const props = defineProps({
    background: {
        type: String,
        required: true
    }
});

const backgroundObject = computed(() => {
    const backgrounds = avatarBackgrounds();
    return backgrounds.find(bg => bg.name === props.background);
});
</script>

<template>
    <svg
        v-if="backgroundObject"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="360px" height="360px" viewBox="0 0 360 360" E="xMidYMid meet">
        <template v-if="backgroundObject.type === 'image'">
            <!-- Voeg hier de geanimeerde afbeelding toe -->
            <image
                id="animated-image"
                :xlink:href="backgroundObject.fileUrl"
                width="105%" height="105%"
                x="50%" y="50%"
                style="transform: translate(calc(-50% - 0.5rem), calc(-50% - 0.5rem));"
            />

            <!-- Voeg hier de animatie toe -->
            <animate xlink:href="#animated-image" attributeName="opacity" repeatCount="indefinite" />
        </template>
        <template v-if="backgroundObject.type === 'color'">
            <polygon id="background" :fill="'#' + backgroundObject.colorCode" points="0,0 360,0 360,360 0,360"/>
        </template>
    </svg>
</template>

<style scoped>

</style>
