<template>
    <div class="country-switcher" v-click-outside="hideMenu" v-if="countries.length > 0">
        <div class="country-switcher__menu" v-show="menuOpen">
            <ul>
                <li v-for="country in countries" :key="country.value" v-if="country.value !== currentCountry" @click="setCountry(country.value)">
                    <img class="d-inline-flex mr-2" width="13" height="13" :src="getFlagByCountry(country.value)" :alt="country.text + ' flag'"> {{ country.text }}
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-center country-switcher__button" @click="toggleMenu">
            <div class="d-flex align-items-center">
                <img class="d-flex mr-2" width="13" height="13" :src="languageFlag" :alt="countryText + ' flag'">
                <span class="mr-2">{{ countryText }}</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "CountrySwitcherComponent",
    data() {
        return {
            defaultCountry: null,
            currentCountry: null,
            countryText: null,
            countries: [
            ],
            menuOpen: false,
        }
    },
    mounted() {
        this.setPreferences();
    },
    computed: {
        languageFlag() {
            let flag = this.getFlagByCountry(this.currentCountry);

            return flag;
        },
        assetPath() {
            return process.env.ASSET_PATH || "";
        },
        nlFlag() {
            return this.assetPath + "/images/icons/netherlands.png";
        },
        usFlag() {
            return this.assetPath + "/images/icons/united-states.png";
        },
        caFlag() {
            return this.assetPath + "/images/icons/canada.png";
        },
        gbFlag() {
            return this.assetPath + "/images/icons/united-kingdom.png";
        },
        auFlag() {
            return this.assetPath + "/images/icons/australia.png";
        },
        nzFlag() {
            return this.assetPath + "/images/icons/new-zealand.png";
        },
        euFlag() {
            return this.assetPath + "/images/icons/european-union.png";
        },
        sgFlag() {
            return this.assetPath + "/images/icons/singapore.png";
        },
        aeFlag() {
            return this.assetPath + "/images/icons/united-arab-emirates.png";
        },
        thFlag() {
            return this.assetPath + "/images/icons/thailand.png";
        },
        inFlag() {
            return this.assetPath + "/images/icons/india.png";
        },
    },
    methods: {
        setPreferences() {
            let country = window.locale.currencyLocale;

            axios.post('/locales', {
                language: window.locale.language
            })
            .then(response => {
                response.data.languages.forEach(language => {
                    this.countries.push({
                        value: language.code,
                        text: language.name
                    });
                });

                if(window.locale.language === 'en' &&
                    country === 'nl-NL') {
                    country = 'en-EU';
                }

                this.defaultCountry = country;
                this.currentCountry = country;

                this.countryText = this.countries.find(x => x.value === this.currentCountry).text;
            });
        },
        setCountry(country) {
            this.currentCountry = country;
            this.updatePreferences();
        },
        updatePreferences() {
            let urls = [];
            urls['nl'] = 'https://virtueleescape.nl';
            urls['en'] = 'https://virtualescaping.com';

            let language = this.currentCountry.split('-')[0];
            let defaultLanguage = this.defaultCountry.split('-')[0];

            if(language !== defaultLanguage) {
                window.location.href = urls[language];
            } else {
                axios.post('/locale', {
                    countryCode: this.currentCountry,
                }).then(() => {
                    location.reload();
                });
            }
        },
        getFlagByCountry(country) {
            let flag;

            switch (country) {
                case 'en-US':
                    flag = this.usFlag;
                    break;
                case 'en-CA':
                    flag = this.caFlag;
                    break;
                case 'en-GB':
                    flag = this.gbFlag;
                    break;
                case 'en-AU':
                    flag = this.auFlag;
                    break;
                case 'en-NZ':
                    flag = this.nzFlag;
                    break;
                case 'nl-NL':
                    flag = this.nlFlag;
                    break;
                case 'en-EU':
                    flag = this.euFlag;
                    break;
                case 'en-SG':
                    flag = this.sgFlag;
                    break;
                case 'en-AE':
                    flag = this.aeFlag;
                    break;
                case 'en-TH':
                    flag = this.thFlag;
                    break;
                case 'en-IN':
                    flag = this.inFlag;
                    break;
                default:
                    flag = this.usFlag;
                    break;
            }

            return flag;
        },
        toggleMenu()
        {
            this.menuOpen = !this.menuOpen;
        },
        hideMenu()
        {
            this.menuOpen = false;
        }
    }
}
</script>

<style scoped>

</style>
