<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-3">
            <div class="formblock">
                <ul
                    class="ve-menu"
                >
                    <li
                        :class="activeMenu === 'details' ? 'active' : ''"
                        @click="activeMenu = 'details'"
                    >
                        Details
                    </li>
                    <li
                        :class="activeMenu === 'avatar' ? 'active' : ''"
                        @click="activeMenu = 'avatar'"
                    >
                        Avatar
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-lg-9">
            <div class="formblock">
                <profile-details
                    :user="this.user"
                    v-show="activeMenu === 'details'"
                />
                <avatar-editor
                    v-show="activeMenu === 'avatar'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AvatarEditor from "./AvatarEditor";
import ProfileDetails from "./ProfileDetails";
export default {
    name: "ProfileEditor",
    components: {ProfileDetails, AvatarEditor},
    props: ['user'],
    data() {
        return {
            activeMenu: 'details'
        }
    }
}
</script>

<style scoped>

</style>
