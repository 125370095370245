function avatarBackgrounds() {
    const assetPath = 'https://dgb3ryo0lspid.cloudfront.net/images/avatar/backgrounds';
    return [
        {
            name: 'white',
            type: 'color',
            colorCode: 'ffffff',
            default: true
        },
        {
            name: 'f5f6eb',
            type: 'color',
            colorCode: 'f5f6eb',
            default: true
        },
        {
            name: 'e5fde2',
            type: 'color',
            colorCode: 'e5fde2',
            default: true
        },
        {
            name: 'd5effd',
            type: 'color',
            colorCode: 'd5effd',
            default: true
        },
        {
            name: 'd1d0fc',
            type: 'color',
            colorCode: 'd1d0fc',
            default: true
        },
        {
            name: 'f7d0fc',
            type: 'color',
            colorCode: 'f7d0fc',
            default: true
        },
        {
            name: 'd0d0d0',
            type: 'color',
            colorCode: 'd0d0d0',
            default: true
        },
        {
            name: 'merlinscastle-stars',
            type: 'image',
            fileUrl: assetPath + '/merlins-castle-bg.gif',
            default: false
        }
    ]
}
export default avatarBackgrounds;
