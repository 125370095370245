<template>
    <div>
        <b-form-group
                :valid-feedback="__('Veel speel plezier!')"
                :invalid-feedback="invalidFeedback"
                :state="state"
                class="voucher-group"
        >
            <b-input-group>
                <b-form-input
                        id="input-1"
                        v-model="voucher"
                        placeholder="Voucher"
                        :state="state"
                        class="voucher"
                        trim
                ></b-form-input>
                <b-input-group-append>
                    <b-button :disabled="!validVoucher" class="btn-voucher" @click="toGame">
                        <span v-if="!voucher || validVoucher || checkedVoucher">{{ toGameText }} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi-arrow-right-short b-icon bi" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
</svg></span>
                        <span v-if="voucher && !checkedVoucher" class="d-flex justify-content-center align-items-center"><b-spinner small :label="__('Controleren')" style="margin-right: .25rem"></b-spinner> {{ __('Controleren') }}</span>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: "VoucherComponent",
        computed: {
            state() {
                if(this.validVoucher) {
                    return true;
                } else if(this.checkedVoucher) {
                    return false;
                } else {
                    return null;
                }
            },
            invalidFeedback() {
                if(this.voucherHasGroup){
                    return this.__('Deze voucher is al gebruikt.')
                } else {
                    return this.__('Deze voucher is niet geldig.')
                }
            },
            toGameText() {
                if(this.voucherHasGroup){
                    return this.__('Meedoen');
                } else {
                    return this.__('Naar het spel');
                }
            }
        },
        data() {
            return {
                voucher: '',
                validVoucher: false,
                checkedVoucher: false,
                voucherHasGroup: false,
            }
        },
        watch: {
            voucher(newVal){
                this.validVoucher = false;
                this.voucherHasGroup = false;
                this.checkedVoucher = false;
                this.checkVoucher(newVal);
            }
        },
        methods: {
            checkVoucher: debounce(function(code) {
                if(code !== '') {
                    let self = this;

                    axios.post('/check-voucher', {
                        code: code
                    }).then(function (response) {
                        self.voucherHasGroup = false;
                        self.checkedVoucher = true;

                        if (response.data) {
                            if (response.data.group) {
                                self.checkGroup(response.data.group);
                            } else {
                                self.voucherHasGroup = false;
                            }
                            self.validVoucher = true;
                        } else {
                            self.validVoucher = false;
                        }
                    });
                } else {
                    self.validVoucher = false;
                    self.checkedVoucher = false;
                }
            }, 1000),
            checkGroup(group) {
                this.voucherHasGroup = true;

                if(group.active){
                    this.validVoucher = true;
                } else {
                    this.validVoucher = false;
                }
            },
            toGame() {
                axios.post('/use-voucher', {
                    code: this.voucher
                }).then(function (response) {
                    window.location.href = response.data.returnUrl;
                });
            }
        }
    }
</script>

<style scoped>

</style>
