<template>
    <div class="row">
        <div class="col-12 col-lg-6 col-xl-7">
            <div class="cart cart-extended">
                <div class="cart-content">
                    <div v-for="(item, itemIndex, index) in items" :key="item.id" class="cart-item d-flex flex-column" :class="{'cart-item__first': index === 0}">
                        <div class="row align-items-center">
                            <div class="col-3 col-md-2 position-relative">
                                <div class="cart-item__image-holder" v-if="item.options.image">
                                    <img
                                        class="cart-item__image cart-item__image-larger"
                                        :src="assetPath + '/images/cards/' + item.options.image + '-1x.png'"
                                    >
                                </div>
                            </div>
                            <div class="col-9 col-md-10">
                                <div class="row align-items-center">
                                    <div class="col-12 col-md-4 p-md-0">
                                        <div class="d-flex align-items-center">
                                            <strong class="cart-item__name">{{ item.options.name }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3 text-left text-md-right mb-1 mb-md-0">
                                        <span class="cart-content__price-original" v-if="item.options.price < item.options.price_original">{{ item.options.price_original * 1 | toCurrency }}</span>{{ item.options.price * 1 | toCurrency }}
                                    </div>
                                    <div class="col-5 col-md-3 text-center">
                                        <div class="cart-item__input-qty">
                                            <div class="cart-item__input-minus" @click="decreaseItemQty(itemIndex)">
                                                -
                                            </div>
                                            <div class="cart-item__input-number">
                                                {{ item.options.qty }}
                                            </div>
                                            <div class="cart-item__input-plus" @click="increaseItemQty(itemIndex)">
                                                +
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-none d-md-flex col-12 col-md-2 text-left text-md-right justify-content-md-end">
                                        {{ item.options.price * item.options.qty | toCurrency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-discount mb-0 pb-0" v-show="totalNumberPrice > 6" style="border-bottom: 0">
                <span class="d-none" @click="showDiscountInput = true" v-if="!showDiscountInput">
                    {{ __('Ik heb een kortingscode') }}<span style="float: right"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi b-icon bi-plus" viewBox="0 0 16 16">
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
    </svg></span>
                </span>
                        <span class="d-block" v-if="!showDiscountInput">
                    {{ __('checkout.promocode' )}} <span class="inline-link" @click="showDiscountInput = true" >{{ __('checkout.click-here') }}</span>
                </span>
                        <div class="cart-discount__input">
                            <input type="text" name="discountcode" :class="{'valid': validDiscountCode, 'error': (checkedDiscountCode && !validDiscountCode)}" v-model="discountCode" :placeholder="__('Kortingscode')" v-if="showDiscountInput">
                            <b-spinner small label="Controleren" v-if="checkingDiscountCode"></b-spinner>
                        </div>
                    </div>
                    <div class="cart-loading" v-if="isLoading">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
            <div class="cart-totals row" v-if="hasLoaded">
                <div class="col-6 d-flex flex-column justify-content-between pb-1">
                    <div class="text-smaller d-flex flex-column align-items-start" v-if="totalPrice !== totalPriceWithDiscount">
                        {{ __('checkout.cart.subtotal') }}<br />
                        {{ __('checkout.cart.save') }}
                        <br /><br />
                    </div>
                    <div style="line-height: 1.875rem;">{{ __('checkout.cart.total') }}</div>
                </div>
                <div class="col-6 d-flex flex-column justify-content-between pb-1">
                    <div v-if="totalPrice !== totalPriceWithDiscount" class="d-flex flex-column align-items-end">
                        <span class="cart-totals__price-without-discount">{{ totalPrice }}</span>
                        <span class="text-smaller">- {{ totalDiscount }}</span>
                        <br />
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        <span class="mr-3 text-gray-light">{{ currency }}</span><span class="text-large font-weight-bolder">{{ totalPriceWithDiscount }}</span>
                    </div>
                </div>
            </div>
            <a :href="__('routes.checkout')" class="btn btn-primary py-3 w-100 px-4 d-flex align-items-center text-center mx-auto mx-md-0">
                <div class="w-100 text-center">
                    {{ __('checkout.to-checkout') }}
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
    name: "CartComponent",
    data() {
        return {
            items: [],
            totalPrice: null,
            totalPriceWithDiscount: null,
            totalDiscount: null,
            currency: null,
            discountCoupons: [],
            isLoading: true,
            hasLoaded: false,
            showDiscountInput: false,
            discountCode: null,
            validDiscountCode: false,
            checkedDiscountCode: false,
            checkingDiscountCode: false,
            totalNumberPrice: 0,
            showCartSummary: false,
            handleToggleElement: null,
        }
    },
    mounted() {
        this.getCartData();
    },
    watch: {
        discountCode(code) {
            this.validDiscountCode = false;
            this.checkedDiscountCode = false;
            this.checkDiscountcode(code);
        }
    },
    computed: {
        assetPath() {
            return process.env.ASSET_PATH || "";
        }
    },
    methods: {
        getTotalNumberPrice() {
            let price = 0;
            Object.values(this.items).forEach((item) => {
                price += item.options.price * item.options.qty;
            });

            this.totalNumberPrice = price;
        },
        checkDiscountcode: debounce(function(code) {
            if(code !== '') {
                let self = this;
                this.checkingDiscountCode = true;

                axios.post('/cart/discountcode', {
                    code: code
                }).then(function (response) {
                    if (response.data.coupon) {
                        self.validDiscountCode = true;
                    } else {
                        self.validDiscountCode = false;
                    }

                    self.getCartData();
                    self.checkedDiscountCode = true;
                    self.checkingDiscountCode = false;
                });
            } else {
                self.validDiscountCode = false;
                self.checkedDiscountCode = false;
                self.checkingDiscountCode = false;
            }
        }, 750),
        getCartData() {
            axios.post('/cart/get-data', {}
            ).then((response) => {
                this.items = response.data.items;
                this.totalPrice = response.data.total_price;
                this.totalPriceWithDiscount = response.data.total_price_with_discount;
                this.totalDiscount = response.data.total_discount;
                this.currency = response.data.currency;
                this.discountCoupons = response.data.discount_coupons;
                this.isLoading = false;
                this.hasLoaded = true;

                if(Object.entries(this.discountCoupons)[0]){
                    this.discountCode = Object.entries(this.discountCoupons)[0][1].code;
                    this.showDiscountInput = true;
                    this.validDiscountCode = true;
                    this.checkedDiscountCode = true;
                    this.checkingDiscountCode = false;
                }

                this.getTotalNumberPrice();
            });
        },
        increaseItemQty(itemIndex) {
            this.isLoading = true;
            axios.post('/cart/increase-item-qty', {
                itemIndex: itemIndex,
            }).then(() => {
                this.$nextTick(() => {
                    this.getCartData();
                });
            });
        },
        decreaseItemQty(itemIndex) {
            this.isLoading = true;
            axios.post('/cart/decrease-item-qty', {
                itemIndex: itemIndex,
            }).then((response) => {
                if (response.data.redirect) {
                    window.location = response.data.redirect;
                } else {
                    this.$nextTick(() => {
                        this.getCartData();
                    });
                }
            });
        },
    }
}
</script>

<style scoped>

</style>
