<script setup>
import AvatarComponent from "../../../components/general/profile/AvatarComponent.vue";
import {useWordleStore} from "./wordleStore";

const wordleStore = useWordleStore();
</script>

<template>
    <div>
        <div class="wordle-score">
            <div class="wordle-users">
                <div class="wordle-users__user"
                     :class="{ 'active': (wordleStore.myTurn && wordleStore.playerOne.id === wordleStore.currentUserId) || (!wordleStore.myTurn && wordleStore.playerTwo.id === wordleStore.currentUserId)}"
                >
                    <div class="wordle-users__user-avatar">
                        <avatar-component
                            :default-avatar="typeof wordleStore.playerOne.avatar === 'string' ? JSON.parse(wordleStore.playerOne.avatar) : wordleStore.playerOne.avatar"
                        />
                    </div>
                    <div class="wordle-users__user-name">
                        {{ wordleStore.playerOne.id === wordleStore.currentUserId ? 'You' : (wordleStore.playerOne.username ?? wordleStore.playerOne.name) }}
                    </div>
                </div>
                <span>VS</span>
                <div class="wordle-users__user"
                     :class="{ 'active': (wordleStore.myTurn && wordleStore.playerTwo.id === wordleStore.currentUserId) || (!wordleStore.myTurn && wordleStore.playerOne.id === wordleStore.currentUserId) }"
                >
                    <div class="wordle-users__user-avatar">
                        <avatar-component
                            :default-avatar="typeof wordleStore.playerTwo.avatar === 'string' ? JSON.parse(wordleStore.playerTwo.avatar) : wordleStore.playerTwo.avatar"
                        />
                    </div>
                    <div class="wordle-users__user-name">
                        {{ wordleStore.playerTwo.id === wordleStore.currentUserId ? 'You' : (wordleStore.playerTwo.username ?? wordleStore.playerTwo.name) }}
                    </div>
                </div>
            </div>
            <div class="progress-bar">
                <div class="inner-bar" :class="{'running': wordleStore.timerRunning}" :style="'width:' + wordleStore.timeLeft / 30 * 100 + '%'"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.wordle-score {
    display: flex;
    flex-direction: column;
}

.progress-bar {
    width: calc(100% - 1rem);
    height: .675rem;
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
    background: #f1f1f1;
    border-radius: .5rem;
    padding: 0 .225rem;
    display: flex;
    justify-content: center;
    margin: 0 .5rem .5rem;
}

.progress-bar .inner-bar {
    height: 40%;
    background-color: red;
    border-radius: .25rem;
}

.progress-bar .inner-bar.running {
    transition: width 1s linear;
}

.wordle-users {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: .25rem;
    background: #f1f1f1;
    border-radius: 2rem;
}

.wordle-users span {
    font-size: .675rem;
}

.wordle-users__user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.425rem .775rem;
    overflow: hidden;
}

.wordle-users__user.active.active {
    background: rgba(0, 0, 0, .1);
    border-radius: 2rem;
}

.wordle-users__user-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: .75rem;
    transform-origin: center;
    transition: transform .15s ease;
}

.wordle-users__user.active .wordle-users__user-avatar {
    transform: scale(1.15);
}

.wordle-users__user-avatar svg {
    width: 100%;
    height: 100%;
}

.wordle-users__user-name {
    font-size: .875rem;
    font-weight: 600;
    text-align: center;
    margin: .375rem 0;
}
</style>
