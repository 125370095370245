<script setup>
import {ref, onMounted, computed, onUnmounted} from 'vue';
import Echo from "laravel-echo";
import WordleScore from "./WordleScore.vue";
import {useWordleStore} from "./wordleStore";

window.Pusher = require('pusher-js');

const guess = ref("");

const alphabet = "qwertyuiopasdfghjklzxcvbnm".split('');
const keyRows = ref([
    'qwertyuiop',
    'asdfghjkl',
    '[zxcvbnm]'
])

const wordleStore = useWordleStore();

onMounted(() => {
    if(!wordleStore.game.bot) {
        const echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.MIX_PUSHER_APP_KEY,
            cluster: process.env.MIX_PUSHER_APP_CLUSTER,
            forceTLS: true
        });

        echo.channel("wordle." . wordleStore.game.id)
            .here(users => {
                console.log('here: ' + users)
            })
            .joining(user => {
                console.log('joining: ' + user)
            })
            .leaving(user => {
                console.log('leaving: ' + user)
            })
            .listen('GameMessageCreated', (event) => {
                console.log('event', event)
            });
    }
});

onMounted(() => {
    document.addEventListener('keydown', handleKeyPress);
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyPress);
    wordleStore.stopTimer();
    guess.value = "";
});

const selectLetter = (letter) => {
    if(!wordleStore.myTurn || wordleStore.gameOver || wordleStore.submitting) {
        return;
    }

    if(letter === ']') {
        guess.value = guess.value.slice(0, -1);
    } else if(letter === '[') {
        if(guess.value.length === 5) {
            submitGuess(guess);
        }
    } else if(guess.value.length < 5) {
        guess.value += letter;
    }
};

function handleKeyPress(event) {
    if(!wordleStore.myTurn || wordleStore.gameOver || wordleStore.submitting) {
        return;
    }

    // Get the pressed key from the event
    const keyPressed = event.key.toLowerCase();
    // Check if the pressed key is a letter from the alphabet
    if (/^[a-z]$/.test(keyPressed)) {
        // Append the pressed letter to the display area
        selectLetter(keyPressed);
    } else if (keyPressed === 'backspace') {
        // Delete the last selected letter
        guess.value = guess.value.slice(0, -1);
    } else if (keyPressed === 'enter') {
        if(guess.value.length === 5) {
            submitGuess(guess.value);
        }
    }
}

function submitGuess(submitGuess) {
    wordleStore.submitGuess(submitGuess).then(() => {
        guess.value = "";
    }, () => {

    });
}
</script>

<template>
    <div class="wordle-game" :class="{'game-over': wordleStore.gameOver}">
            <wordle-score />

            <div class="guesses">
                <div class="guesses-container">
                    <div v-for="(guess, index) in wordleStore.previousGuesses" :key="'previous-guess-' + index">
                        <div class="guess-block">
                            <span v-for="(letter, i) in guess.word" :key="'letter-' + i" class="letter-block" :class="{ 'correct-guess': guess.correctLetters[i], 'close-guess': guess.closeLetters[i] }">{{ letter }}</span>
                        </div>
                    </div>
                    <div v-show="!(wordleStore.gameOver && wordleStore.previousGuesses.length > 5)"
                         class="guess-block"
                         :class="{'error': wordleStore.guessError}">
                        <span v-for="(letter, index) in guess.split('')" :key="'guess-letter-' + index" class="letter-block has-letter">{{ letter }}</span>
                        <span v-for="i in Math.max(5 - guess.length)" :key="'guess-letter-placeholder-' + i" class="letter-block"></span>
                    </div>
                    <div v-for="index in Math.max(0, 5 - wordleStore.previousGuesses.length)" :key="'placeholder-' + index">
                        <div class="guess-block">
                            <span v-for="i in 5" :key="'letter-placeholder-' + i" class="letter-block"></span>
                        </div>
                    </div>
                </div>
            </div>

                <div class="keyboard">
                    <div v-for="(row, index) in keyRows" :key="row" class="keyboard__row">
                        <div v-if="index === 1" class="spacer"></div>
                        <button
                            v-for="letter in row.split('')"
                            :key="letter"
                            @click="selectLetter(letter)"
                            :disabled="!wordleStore.myTurn"
                            :class="{ 'correct-letter': wordleStore.correctLetters.includes(letter), 'close-letter': wordleStore.closeLetters.includes(letter), 'wrong-letter': wordleStore.wrongLetters.includes(letter), 'enter': letter === '[', 'backspace': letter === ']'}"
                        >
                            <template
                                v-if="!['[', ']'].includes(letter)"
                            >
                                {{ letter}}
                            </template>
                            <template v-if="letter === '['">
                                Enter
                            </template>
                            <template v-if="letter === ']'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                    <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                                    <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                                </svg>
                            </template>
                        </button>
                        <div v-if="index === 1" class="spacer"></div>
                    </div>
                </div>
    </div>
</template>

<style>
.wordle-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31.25rem;
}

.wordle-game.game-over .keyboard {
    opacity: .5;
}

.wordle-game.game-over .keyboard button {
    cursor: default;
}

.keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.keyboard__row {
    display: flex;
    width: 100%;
    margin: 0 auto .375rem;
    touch-action: manipulation;
    gap: .375rem;
}

.keyboard__row .spacer {
    flex: .5;
}

.keyboard button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 3.625rem;
    border-radius: .25rem;
    cursor: pointer;
    flex: 1;
    background-color: #818384;
    border: 0;
    color: #fff;
    font-weight: 700;
    -webkit-tap-highlight-color: rgba(0, 0, 0, .3);
    transition: background-color .1s ease, color .1s ease;
    padding: 0;
}

.keyboard button.enter, .keyboard button.backspace {
    font-size: .675rem;
    flex: 1.5;
}

.keyboard button.wrong-letter {
    background-color: #3a3a3c;
}

.keyboard button.close-letter {
    background-color: #d5bf4f;
}

.keyboard button.correct-letter {
    background-color: #17a539;
}

.guesses {
    position: relative;
    margin-bottom: .625rem;
    height: 18.25rem;
    width: 15.375rem;
    overflow: hidden;
    padding: .375rem;
}

.guesses-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow-y: auto;
    height: 18.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
    padding: .375rem;
}

.guess-block {
    display: flex;
    gap: .5rem;
}

.guess-block.error {
    animation: horizontal-shaking 0.25s linear;
    transform-origin: center;
}

.letter-block.has-letter {
    animation: zoom-in-out 0.15s linear;
    transform-origin: center;
}

.letter-block {
    display: inline-flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: #a5a5a5;
    border-radius: .25rem;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}

.close-guess {
    background-color: #d5bf4f;
}

.correct-guess {
    background-color: #17a539;
}
</style>
