<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="360px"
        height="360px"
        viewBox="0 0 360 360"
        E="xMidYMid meet"
        v-if="loaded"
    >
        <avatar-background :background="background"></avatar-background>
        <avatar-hair-back :hair="hairStyle" :color="hairColorBack(hairColor)" />
        <avatar-skin-color :color="skin" />
        <avatar-tattoos :tattoos="tattoos" />
        <avatar-eyes :eyes="eyes" />
        <avatar-eyebrows :eyebrows="eyebrows" />
        <avatar-mouth :mouth="mouth" />
        <avatar-clothes :clothes="clothes" :color="fabricColor" />
        <avatar-accessories :accessory="accessories" />
        <avatar-facial-hair :facial-hair="facialHair" :color="hairColorFacial(hairColor)" />
        <avatar-hair-front :hair="hairStyle" :color="hairColorFront(hairColor)" />
        <avatar-glasses :glasses="glasses" :opacity="glassOpacity" />
    </svg>
</template>

<script>
import AvatarEyes from "./avatar/AvatarEyes";
import AvatarMouth from "./avatar/AvatarMouth";
import AvatarHairBack from "./avatar/AvatarHairBack";
import AvatarHairFront from "./avatar/AvatarHairFront";
import AvatarAccessories from "./avatar/AvatarAccessories";
import AvatarEyebrows from "./avatar/AvatarEyebrows";
import AvatarFacialHair from "./avatar/AvatarFacialHair";
import AvatarGlasses from "./avatar/AvatarGlasses";
import AvatarClothes from "./avatar/AvatarClothes";
import AvatarTattoos from "./avatar/AvatarTattoos";
import AvatarSkinColor from "./avatar/AvatarSkinColor";
import AvatarBackground from "../../../profile/components/avatar/AvatarBackground.vue";

export default {
    name: "AvatarComponent",
    components: {
        AvatarBackground,
        AvatarSkinColor,
        AvatarTattoos,
        AvatarClothes,
        AvatarGlasses,
        AvatarFacialHair,
        AvatarEyebrows,
        AvatarAccessories,
        AvatarHairBack,
        AvatarHairFront,
        AvatarMouth,
        AvatarEyes
    },
    props: ['defaultAvatar'],
    data() {
        return {
            skin: '',
            eyes: '',
            eyebrows: '',
            mouth: '',
            hairStyle: '',
            hairColor: '',
            facialHair: '',
            clothes: '',
            fabricColor: '',
            background: '',
            glasses: '',
            glassOpacity: 0,
            tattoos: '',
            accessories: '',
            loaded: false
        }
    },
    mounted() {
        if(!this.defaultAvatar) {
            this.load();
        } else {
            this.setAvatarData(this.defaultAvatar);
        }
    },
    methods: {
        hairColorFront(color) {
            return color.split("_")[0];
        },
        hairColorBack(color) {
            return color.split("_")[1];
        },
        hairColorFacial(color) {
            return color.split("_")[2];
        },
        load() {
            const avatar = localStorage.getItem("profileAvatar");

            if(!avatar) {
                axios.get('/profile/id').then(response => {
                    if (response.data) {
                        axios.get('/profile/' + response.data + '/avatar').then(response => {
                            if (response.data) {
                                this.setAvatarData(response.data);
                                this.saveAvatar();
                            }
                        });
                    }
                });
            } else {
                this.setAvatarData(JSON.parse(avatar));
            }
        },
        setAvatarData(avatar) {
            if(avatar) {
                this.skin = avatar.skin;
                this.eyes = avatar.eyes;
                this.eyebrows = avatar.eyebrows;
                this.mouth = avatar.mouth;
                this.hairStyle = avatar.hairstyle;
                this.hairColor = avatar.haircolor;
                this.facialHair = avatar.facialhair;
                this.clothes = avatar.clothes;
                this.fabricColor = avatar.fabriccolor;
                this.background = avatar.background;
                this.glasses = avatar.glasses;
                this.glassOpacity = parseFloat(avatar.glassopacity);
                this.tattoos = avatar.tattoos;
                this.accessories = avatar.accessories;

                this.$emit('loaded', avatar);
                this.loaded = true;
            }
        },
        updateAvatar(type, value)
        {
            switch (type) {
                case 'skin':
                    this.skin = value;
                    break;
                case 'eyes':
                    this.eyes = value;
                    break;
                case 'eyebrows':
                    this.eyebrows = value;
                    break;
                case 'mouth':
                    this.mouth = value;
                    break;
                case 'hairstyle':
                    this.hairStyle = value;
                    break;
                case 'haircolor':
                    this.hairColor = value;
                    break;
                case 'facialhair':
                    this.facialHair = value;
                    break;
                case 'clothes':
                    this.clothes = value;
                    break;
                case 'fabriccolor':
                    this.fabricColor = value;
                    break;
                case 'background':
                    this.background = value;
                    break;
                case 'glasses':
                    this.glasses = value;
                    break;
                case 'glassopacity':
                    this.glassOpacity = value;
                    break;
                case 'tattoos':
                    this.tattoos = value;
                    break;
                case 'accessories':
                    this.accessories = value;
                    break;
            }
        },
        saveAvatar()
        {
            const avatar = {
                skin: this.skin,
                eyes: this.eyes,
                eyebrows: this.eyebrows,
                mouth: this.mouth,
                hairstyle: this.hairStyle,
                haircolor: this.hairColor,
                facialhair: this.facialHair,
                clothes: this.clothes,
                fabriccolor: this.fabricColor,
                background: this.background,
                glasses: this.glasses,
                glassopacity: this.glassOpacity,
                tattoos: this.tattoos,
                accessories: this.accessories
            };

            localStorage.setItem("profileAvatar", JSON.stringify(avatar));
        }
    }
}
</script>

<style scoped>
</style>
