<script setup>

import {onMounted, ref, watch} from "vue";
import TeamRanking from "./rooms/TeamRanking.vue";

const props = defineProps({
    room: {
        type: Object,
        required: true
    }
})

const fastestTeams = ref([]);
const sprintTeams = ref([]);
const initFastest = ref(false);
const tab = ref('sprint');

onMounted(() => {
    getSprintTeams();
})

function getFastestTeams() {
    axios.post('/highscore', {
        room_id: props.room.id,
    }).then((results) => {
        fastestTeams.value = results.data.teams;
    });
}

function getSprintTeams() {
    axios.post('/highscore/sprint', {
        room_id: props.room.id,
    }).then((results) => {
        sprintTeams.value = results.data.teams;
    });
}

function changeTab(newTab) {
    tab.value = newTab;

    if(newTab === 'all-time' && !initFastest.value) {
        getFastestTeams();
        initFastest.value = true;
    }
}
</script>

<template>
    <div>
        <div class="escaperoom-highscore">
            <h3>Top teams</h3>
            <div class="escaperoom-highscore__tabs">
                <div class="escaperoom-highscore__tabs-tab" :class="tab === 'sprint' ? 'active' : ''"
                     @click="changeTab('sprint')"
                >
                    Sprint
                </div>
                <div class="escaperoom-highscore__tabs-tab" :class="tab === 'all-time' ? 'active' : ''"
                     @click="changeTab('all-time')"
                >
                    All Time
                </div>
            </div>
            <team-ranking
                v-show="tab === 'sprint'"
                :teams="sprintTeams" />
            <team-ranking
                v-if="initFastest"
                v-show="tab === 'all-time'"
                :teams="fastestTeams" />
        </div>
        <small v-show="tab === 'all-time'">* {{ __('met maximaal 3 hints') }}</small>
    </div>
</template>

<style scoped>

</style>
