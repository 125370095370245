<template>
    <div class="carousel-products">
        <carousel v-if="loaded"
                  :nav="false"
                  :loop="false"
                  :center="false"
                  :margin="20"
                  :responsive="{
                      0:{items:1, stagePadding:50, touchDrag: true},
                      768:{items:2},
                      1200:{items:3}
                  }"
        >
            <carousel-card
                v-for="product in products"
                :key="product.id"
                :product="product"
                v-if="(includeGiftcard || product.id !== 5) && product.locale_price"
            />
        </carousel>
    </div>
</template>

<script>
import carouselCard from "./CarouselCard";

export default {
    name: "CarouselComponent",
    components: {
        carousel: () => import('vue-owl-carousel'),
        carouselCard
    },
    props: {
        includeGiftcard: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            products: [],
            loaded: false,
        }
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        getProducts()
        {
            axios.post('/product/all', {
                localeCode: window.locale.currencyLocale
            })
                .then(response => {
                    this.products = response.data;
                    this.loaded = true;
                });
        }
    }
}
</script>

<style scoped>

</style>
