<template>
    <a :href="slug(product)" class="carousel-products__product" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <div class="carousel-products__product-top" :class="trailer(product) ? 'with-video' : ''" >
            <div v-if="product.locale_price.price < product.locale_price.price_original && ((room(product) && room(product).active) || !room(product))"
                 class="carousel-products__product-label carousel-products__product-label-discount carousel-products__product-label-red"
                 v-html="'-' + calculateDiscount(product.locale_price.price, product.locale_price.price_original) + '%'"></div>
            <div v-if="product.label"
                 class="carousel-products__product-label carousel-products__product-label-purple"
                 v-html="product.label"></div>
            <div
                v-if="trailer(product)"
                class="carousel-products__product-video"
            >
                <div
                    :id="'youtube-player-' + trailer(product)"
                ></div>
            </div>
            <picture
                v-if="product.image"
            >
                <source
                    :srcset="assetPath + '/images/cards/' + product.image + '-2x.webp'"
                    type="image/webp"
                >
                <source
                    :srcset="assetPath + '/images/cards/' + product.image + '-4x.png 1600w, ' + assetPath + '/images/cards/' + product.image + '-2x.png 540w, ' + assetPath + '/images/cards/' + product.image + '-1x.png 367w'"
                    type="image/png"
                >
                <img
                    v-if="product.image"
                    :src="assetPath + '/images/cards/' + product.image + '-1x.png'"
                    :srcset="assetPath + '/images/cards/' + product.image + '-4x.png 1600w, ' + assetPath + '/images/cards/' + product.image + '-2x.png 540w, ' + assetPath + '/images/cards/' + product.image + '-1x.png 367w'"
                    :alt="product.name"
                    loading="lazy"
                    width="370"
                    height="200"
                    @load="onImageLoad"
                >
            </picture>
        </div>
        <div class="carousel-products__product-info">
            {{ product.name }}
            <div
                v-if="(room(product) && room(product).active) || !room(product)"
                class="carousel-products__product-price"
            >
                    <span v-if="product.locale_price.price < product.locale_price.price_original" class="original-price">
                        {{ product.locale_price.locale.currency_symbol }}{{ product.locale_price.price_original }}
                    </span>
                <span class="current-price">
                        {{ product.locale_price.locale.currency_symbol }}{{ product.locale_price.price | toPrice }}
                    </span>
            </div>
        </div>
    </a>
</template>

<script>

export default {
    name: "CarouselCard",
    props: {
        product: {
            type: Object
        },
    },
    data() {
        return {
            player: null,
            playerReady: false,
            lazyLoaded: false,
            videoShouldBeLoaded: false
        }
    },
    computed: {
        assetPath() {
            return process.env.ASSET_PATH || "";
        }
    },
    mounted() {
        if(this.trailer(this.product)) {
            this.waitForYTToLoad();
        }
    },
    methods: {
        waitForYTToLoad(){
            if(typeof window.YT !== "undefined" && window.YT.loaded){
                this.$nextTick(() => {
                    this.loadVideo();
                });
            }
            else{
                setTimeout(this.waitForYTToLoad, 250);
            }
        },
        insertVideo()
        {
            const youtubeApiId = 'youtube-iframe-api';
            this.videoShouldBeLoaded = true;

            if(!window.YT) { // If not, load the script asynchronously
                if (!document.getElementById(youtubeApiId)) {
                    const tag = document.createElement('script');
                    tag.src = 'https://www.youtube.com/iframe_api';
                    tag.id = youtubeApiId;

                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                }
            } else {
                this.loadVideo();
            }
        },
        loadVideo()
        {
            this.$nextTick(() => {
                if(window.YT.Player) {
                    this.player = new window.YT.Player('youtube-player-' + this.trailer(this.product), {
                        videoId: this.trailer(this.product),
                        playerVars: {
                            'playsinline': 1,
                            'controls': 0,
                            'rel': 0,
                            'enablejsapi': 1,
                            'modestbranding': 1,
                            'showinfo': 0,
                            'disablekb': 1,
                            'iv_load_policy': 3,
                            'loop': 1,
                            'playlist': this.trailer(this.product)
                        },
                        events: {
                            onReady: this.videoReady,
                            onEnded: this.videoEnded
                        },
                    });
                }
            });
        },
        videoReady()
        {
            this.playerReady = true;
            if(typeof this.player.playVideo === "function") {
                this.player.mute();
                this.player.playVideo();
            }
        },
        videoEnded()
        {
            this.player?.seekTo(0);
        },
        mouseOver()
        {
            if(this.playerReady && typeof this.player?.playVideo === "function") {
                this.player.playVideo();
            } else {
                if(!this.videoShouldBeLoaded) {
                    this.$nextTick(() => {
                        this.insertVideo();
                    });
                }
            }
        },
        mouseLeave()
        {
            if(this.playerReady) {
                setTimeout(() => {
                    if(typeof this.player?.pauseVideo === "function") {
                        this.player.pauseVideo();
                    }
                }, 300);
            }
        },
        slug(product)
        {
            let baseSlug = '';
            if(product.type === 1) {
                baseSlug = '/room';
            } else if(product.type === 2) {
                baseSlug = this.__('routes.bundle')
            }

            return baseSlug + '/' + product.slug;
        },
        room(product)
        {
            return product.rooms[0];
        },
        trailer(product)
        {
            const room = this.room(product);
            const language = window.locale.language;

            if(room && room.trailer) {
                const trailerUrl = room.trailer[language];
                if(trailerUrl) {
                    return trailerUrl.split('/embed/')[1];
                }
            }

            return null;
        },
        calculateDiscount(current_price, original_price)
        {
            return Math.round((original_price - current_price) / original_price * 100);
        },
        onImageLoad()
        {
            this.lazyLoaded = true;
        }
    }
}
</script>

<style scoped>

</style>
