<script>
import WordleGame from "./WordleGame.vue";
import WordleIntro from "./WordleIntro.vue";
import {useWordleStore} from "./wordleStore";
import WordleResult from "./WordleResult.vue";

export default {
    name: "WordleScene",
    components: {WordleResult, WordleIntro, WordleGame},
    data() {
        return {
            users: [],
            invitedUserId: null,
            joiningGame: false,
            game: null,
            botTimeout: null,
            playerOne: null,
            playerTwo: null,
            wordleStore: null
        }
    },
    mounted() {
        this.wordleStore = useWordleStore();
        this.wordleStore.joinLobby();
    },
    methods: {
        inviteOtherUser() {
            const otherUser = this.users.find(user => user.id !== Laravel.user.id);
            this.invitedUserId = otherUser.id;

            axios.post('/api/v1/games/wordle-versus/invite',
                {
                    user_id: otherUser.id
                }).then((response) => {
                    if(response.data.user) {
                        this.invitedUserId = response.data.user.id
                    } else {
                        this.invitedUserId = null
                    }
            });
        },
        joinGame(event) {
            this.joiningGame = true;
            this.wordleStore.joinGame(event).then(() => {
                this.joiningGame = false;
            });
        },
        introEnded() {
            this.wordleStore.startGame();
        }
    }
}
</script>

<template>
    <div class="wordle">
        <template v-if="!wordleStore?.game">
            <p>Finding opponent...</p>
        </template>
        <template v-else>
            <wordle-intro @ended="introEnded"/>
            <wordle-game />
            <wordle-result v-if="wordleStore.gameOver"/>
        </template>
    </div>
</template>

<style scoped>
.wordle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 1.5rem .5rem;
}
</style>
