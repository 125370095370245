<template>
    <div class="reviews">
        <carousel :nav="false" :loop="false" :center="false" :responsive="{0:{items:1},768:{items:2},1200:{items:3}}">
        <div class="reviews-review" v-for="(review, index) in reviews">
            <div class="reviews-review__card box-card box-card__shadow">
                <div class="reviews-review__card-top">
                    <div class="reviews-review__picture">
                        <img :src="review.picture" v-if="review.picture" :alt="review.name" loading="lazy">
                        <span class="reviews-review__no-picture" v-if="!review.picture">{{ review.name[0] }}</span>
                    </div>
                    <div class="reviews-review__user">
                        <div class="reviews-review__user-name">
                            {{ review.name }}
                        </div>
                        <div class="reviews-review__user-rating" v-html="starsRating(review.rating)"></div>
                    </div>
                    <div class="reviews-review__platform" v-html="platformIcon(review.platform)"></div>
                </div>
                <div class="reviews-review__content">
                    <div v-if="review.text_short.length < review.text.length">{{ review.text_short }} ... <span class="link" @click="showReview(index)">{{ __('Lees meer')}}</span></div>
                    <div v-if="review.text_short.length === review.text.length">{{ review.text }}</div>
                    <div class="reviews-review__content-date">
                    </div>
                </div>
            </div>
        </div>
        </carousel>
        <div class="reviews-modal__backdrop" v-if="showReviewModal" @click.self="hideReviewModal">
            <div class="reviews-modal box-card box-card__shadow">
                <div class="reviews-review__card-top">
                    <div class="reviews-review__picture">
                        <img :src="reviews[activeReview].picture" v-if="reviews[activeReview].picture" loading="lazy">
                        <span class="reviews-review__no-picture" v-if="!reviews[activeReview].picture">{{ reviews[activeReview].name[0] }}</span>
                    </div>
                    <div class="reviews-review__user">
                        <div class="reviews-review__user-name">
                            {{ reviews[activeReview].name }}
                        </div>
                        <div class="reviews-review__user-rating" v-html="starsRating(reviews[activeReview].rating)"></div>
                    </div>
                    <div class="reviews-review__platform" v-html="platformIcon(reviews[activeReview].platform)"></div>
                </div>
                <div class="reviews-review__content">
                    <div v-html="reviews[activeReview].text"></div>
                    <div class="reviews-review__content-date">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
    name: "ReviewsComponent",
    components: {
        carousel
    },
    data() {
        return {
            activeReview: 0,
            showReviewModal: false
        }
    },
    computed: {
        assetPath() {
            return "https://dgb3ryo0lspid.cloudfront.net"; //process.env.ASSET_PATH || "";
        },
        reviewImage1() {
            return this.assetPath + "/images/reviews/charley-geering-wels.png";
        },
        reviewImage2() {
            return this.assetPath + "/images/reviews/shirley-louws.png";
        },
        reviewImage3() {
            return this.assetPath + "/images/reviews/natalia-odelvskaia.png";
        },
        reviewImage4() {
            return this.assetPath + "/images/reviews/ultimate-gaming-friends.png";
        },
        reviewImage5() {
            return this.assetPath + "/images/reviews/nienke-timmermans.png";
        },
        reviewImage6() {
            return this.assetPath + "/images/reviews/kim-schaefer-ritzen.png";
        },
        reviewImage8() {
            return this.assetPath + "/images/reviews/ilse-ent.png";
        },
        reviewImageEn1() {
            return this.assetPath + "/images/reviews/chloe-smith.webp";
        },
        reviewImageEn2() {
            return this.assetPath + "/images/reviews/michelle-mckinley-bell.png";
        },
        reviewImageEn3() {
            return this.assetPath + "/images/reviews/michael-boutros.png";
        },
        reviewImageEn4() {
            return this.assetPath + "/images/reviews/kelly-bednar.png";
        },
        reviewImageEn5() {
            return this.assetPath + "/images/reviews/lisa-elliot.png";
        },
        reviewImageEn6() {
            return this.assetPath + "/images/reviews/juliet-taylor.png";
        },
        reviewImageEn7() {
            return this.assetPath + "/images/reviews/hope-thomas.png";
        },
        reviewImageEn8() {
            return this.assetPath + "/images/reviews/kirsten-sandland-kroeker.png";
        },
        reviewImageEn9() {
            return this.assetPath + "/images/reviews/neal-jones.png";
        },
        reviews() {
            if(this.$lang().locale === 'nl') {
                return [
                    {
                        'name': 'Charley Geerings-Wels',
                        'text_short': 'Vanavond met 4 meiden totaal de expeditie Noordpool gespeeld. Conclusie is dat ik graag nog eens een escape room online speel via Virtual Escapig. Super orgineel opgezet, wij vonden echt wel dat er leuk denk werk in zat. Ik had een klein technisch',
                        'text': 'Vanavond met 4 meiden totaal de expeditie Noordpool gespeeld. Conclusie is dat ik graag nog eens een escape room online speel via Virtual Escapig.<br />Super orgineel opgezet, wij vonden echt wel dat er leuk denk werk in zat.<br /><br />Ik had een klein technisch probleem, wat ook nog eens aan mijn eigen webbrowser lag, was binnen no time verholpen! dus ook de ondersteuning is top geregeld!<br /><br />👍 keep up the good work, virtual escaping!',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage1,
                        'platform': 'facebook',
                    },
                    // {
                    //     'name': 'Anita Wuestman',
                    //     'text_short': 'We hebben een hele leuke avond gehad met de Noordpool Expeditie.  Het was lekker puzzelen met alle bruikbare en onbruikbare informatie. Soms vonden we een aanwijzing snel en andere keren zaten we op het verkeerde been. Eerlijk is eerlijk, bij een van de',
                    //     'text': 'We hebben een hele leuke avond gehad met de Noordpool Expeditie.  Het was lekker puzzelen met alle bruikbare en onbruikbare informatie. Soms vonden we een aanwijzing snel en andere keren zaten we op het verkeerde been. Eerlijk is eerlijk, bij een van de opdrachten hadden we niet genoeg aan de hint in het spel zelf maar daar bracht de chat met Virtual Escaping uitkomst.<br /><br />Een voordeel van deze game is dat we binnen het spel konden videobellen en chatten.<br />Verder zit deze game technisch en grafisch ook goed inelkaar.<br /><br />Een aanrader dus!',
                    //     'rating': 5,
                    //     'date': '',
                    //     'picture': 'https://scontent-ams4-1.xx.fbcdn.net/v/t1.0-9/136411353_4275306669165159_948750956985769541_n.jpg?_nc_cat=110&ccb=3&_nc_sid=09cbfe&_nc_ohc=xCPfUYar89sAX8o-cLe&_nc_ht=scontent-ams4-1.xx&oh=21b8c654a87f1a530f72ba80827d218e&oe=604BC3C9',
                    //     'platform': 'facebook',
                    // },
                    {
                        'name': 'Shirley Louws',
                        'text_short': 'Vandaag met mijn twee dochters (11 en 13) de Noordpool gedaan! Wat ontzettend leuk om in deze tijd, waarin weinig kan/mag, even iets anders te doen samen! Wel wat hints nodig, maar who cares, we hebben het gehaald!! Gaan zeker de andere ook',
                        'text': 'Vandaag met mijn twee dochters (11 en 13) de Noordpool gedaan! Wat ontzettend leuk om in deze tijd, waarin weinig kan/mag, even iets anders te doen samen!<br />Wel wat hints nodig, maar who cares, we hebben het gehaald!!<br />Gaan zeker de andere ook uitproberen!',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage2,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Natalia Odelevskaia',
                        'text_short': 'We hebben een fantastisch verjaardagsfeest met virtuele escaperoom De ondergrondse moord gehad. 4 teams, 20 mensen uit 3 verschillende landen hebben samen gespeeld. Bedankt voor deze avontuurlijke avond!',
                        'text': 'We hebben een fantastisch verjaardagsfeest met virtuele escaperoom De ondergrondse moord gehad. 4 teams, 20 mensen uit 3 verschillende landen hebben samen gespeeld. Bedankt voor deze avontuurlijke avond!',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage3,
                        'platform': 'google',
                    },
                    {
                        'name': 'Ultimate Gaming Friends !!!',
                        'text_short': 'Ik ben 16 geworden tijdens de covid 19 en had een virtuele  escape gekregen, het was in een woord geweldig, misschien nog wel leuker dan echte escape rooms. de game\'s zitten goed in elkaar en zijn soepel te spelen, als je niet bij elkaar kan zijn kan je',
                        'text': 'Ik ben 16 geworden tijdens de covid 19 en had een virtuele  escape gekregen, het was in een woord geweldig, misschien nog wel leuker dan echte escape rooms. de game\'s zitten goed in elkaar en zijn soepel te spelen, als je niet bij elkaar kan zijn kan je deze escape rooms ook spelen door middel van webcam en voice chat!',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage4,
                        'platform': 'google',
                    },
                    {
                        'name': 'Nienke Timmermans',
                        'text_short': 'Gister de moord bij de metro opgelost! Super leuke escape!  Af en toe liepen we wat vast maar uiteindelijk is het ons gelukt hem zonder hints op te lossen! Een gezellige avond gehad met collega’s! Enige puntje is dat in niet beide collega’s tegelijk in',
                        'text': 'Gister de moord bij de metro opgelost! Super leuke escape!  Af en toe liepen we wat vast maar uiteindelijk is het ons gelukt hem zonder hints op te lossen! Een gezellige avond gehad met collega’s! Enige puntje is dat in niet beide collega’s tegelijk in beeld kreeg maar telkens maar 1 tegelijk. Dus zag alleen degene die op dat moment aan het woord was.<br />Al met al een aanrader!',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage5,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Kim Schaefer-Ritzen',
                        'text_short': 'Vanavond samen met familie vanaf 3 verschillende plekken expeditie noordpool gespeeld. Echt een aanrader! Goeie puzzels, sommige breinbrekers, maar goed op te lossen! Erg leuk ook nu in covid tijd met avondklok om samen iets te kunnen doen. Zeker ',
                        'text': 'Vanavond samen met familie vanaf 3 verschillende plekken expeditie noordpool gespeeld. Echt een aanrader! Goeie puzzels, sommige breinbrekers, maar goed op te lossen! Erg leuk ook nu in covid tijd met avondklok om samen iets te kunnen doen. Zeker doordat je camera en microfoon aan kunt zetten en daardoor goed met elkaar kunt communiceren.',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage6,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Evanne Dongstra',
                        'text_short': 'Eerste escape room ooit. Ik vond het erg leuk, door samen te werken kom je er uiteindelijk uit! Je moet allerlei aanwijzingen combineren. Goeie visuals, en het klikken werkt ook soepel.',
                        'text': 'Eerste escape room ooit. Ik vond het erg leuk, door samen te werken kom je er uiteindelijk uit! Je moet allerlei aanwijzingen combineren. Goeie visuals, en het klikken werkt ook soepel.',
                        'rating': 5,
                        'date': '',
                        'picture': null,
                        'platform': 'google',
                    },
                    {
                        'name': 'Ilse Ent',
                        'text_short': 'Met vriendinnen de ondergrondse moord weten op te lossen. Absoluut geen toptijd, maar daardoor extra lang met elkaar online kunnen spelen 🙂 Fijne aan deze virtuele escaperoom is dat je direct binnen het spel met elkaar kunt videobellen',
                        'text': 'Met vriendinnen de ondergrondse moord weten op te lossen. Absoluut geen toptijd, maar daardoor extra lang met elkaar online kunnen spelen 🙂<br />Fijne aan deze virtuele escaperoom is dat je direct binnen het spel met elkaar kunt videobellen en dus geen extra voorziening daarvoor hoeft op te zetten. Een absolute aanrader. Meteen afgesproken de noordpoolexpeditie ook te gaan doen. Ook qua prijs is deze escaperoom heel gunstig. In alle opzichten dus een aanrader! Hopelijk volgen er nog meer games',
                        'rating': 5,
                        'date': '',
                        'picture': this.reviewImage8,
                        'platform': 'facebook',
                    },
                ];
            } else {
                return [
                    {
                        'name': 'Chloe Smith',
                        'text_short': 'Out of the virtual escapes I have played, I would say this one is the best! I like everything was on one page, and they have their own in app video chat feature. No need to open separate web pages, and there’s no side slides, everything is done on the one',
                        'text': 'Out of the virtual escapes I have played, I would say this one is the best! I like everything was on one page, and they have their own in app video chat feature. No need to open separate web pages, and there’s no side slides, everything is done on the one graphic page. The graphics are good, and locks are opened by clicking on them, rather than typing in codes like other escape rooms I have played. Perfect way to catch up with people from multiple locations for a couple of hours, well worth the money! May be a little more than some others online, but it’s easy to see why it’s worth more! Definitely recommend!',
                        'rating': 5,
                        'date': '2021-01-29 23:47:00',
                        'picture': this.reviewImageEn1,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Michelle McKinley Bell ',
                        'text_short': 'Having been a fan of physical escape rooms we thought we would try a virtual one. This was the first virtual escape we have tried and we were not disappointed. We didn’t know what to expect! Logging into the room was easy and we linked up with friends',
                        'text': 'Having been a fan of physical escape rooms we thought we would try a virtual one. This was the first virtual escape we have tried and we were not disappointed. We didn’t know what to expect! Logging into the room was easy and we linked up with friends from another location. It was great to see each other and be able to talk to each other solving the clues as we went. Some of the elements were very clever and you really felt like you were on a mission. What was great was that when we did get a little stuck we were able to message Virtual escaping and someone kindly helped us. This we were very thankful for and think now we have done one virtual room we will find the next easier to navigate having done it once. Very clever how you have different things to click on, a variety of different rooms to collect codes and some very unique features. Would highly recommend and definitely will be doing another. We were very pleased we were not against the clock as we took our time. 🤣 Thankyou for a fun lockdown evening. 🌟🌟🌟🌟🌟',
                        'rating': 5,
                        'date': '2021-01-29 23:47:00',
                        'picture': this.reviewImageEn2,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Michael Boutros',
                        'text_short': 'Used Virtual Escaping to run a virtual escape room with a high school youth group and it was amazing!! Very easy to coordinate and the youth had so much fun! On top of that, their customer service is excellent! I had purchased more vouchers than what',
                        'text': 'Used Virtual Escaping to run a virtual escape room with a high school youth group and it was amazing!! Very easy to coordinate and the youth had so much fun! On top of that, their customer service is excellent! I had purchased more vouchers than what was needed and they were very helpful and kind enough to refund the unused vouchers. Definitely will be using their escape rooms again in the future!',
                        'rating': 5,
                        'date': '2021-01-29 23:47:00',
                        'picture': this.reviewImageEn3,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Kelly Bednar',
                        'text_short': 'This was so fun—we played with my siblings who are spread out in different states, so we don’t see each other often. This was a great way to connect to family. You see and hear them in the video chat throughout the game so that you can communicate',
                        'text': 'This was so fun—we played with my siblings who are spread out in different states, so we don’t see each other often. This was a great way to connect to family. You see and hear them in the video chat throughout the game so that you can communicate with each other and work together. The storyline of the game was intriguing and the “puzzles” you have to solve are challenging, but at just the right level. My sister had never done a real escape room before and didn’t think she’d be any good at it, but she ended up enjoying it and we all contributed to solving the murder!  This was our first virtual escape room for all of us and we really enjoyed it and found it easy to navigate through (little to no previous experience required to play). We would all highly recommend this to anyone looking to have a fun game night in and we’ll definitely be playing the other game soon!',
                        'rating': 5,
                        'date': '2021-01-29 23:47:00',
                        'picture': this.reviewImageEn4,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Lisa Elliott',
                        'text_short': 'Played the murder on the underground last night and loved it. I play a lot of online escape games and this one was great, it had interactive puzzles, clever maths and logic as well as a great underlying story. Totally recommend and am coming back',
                        'text': 'Played the murder on the underground last night and loved it. I play a lot of online escape games and this one was great, it had interactive puzzles, clever maths and logic as well as a great underlying story. Totally recommend and am coming back next week to try the North Pole one! There were 2 of us playing and it took about 90 mins, its not too easy or hard..  just taxing enough to be fun!',
                        'rating': 5,
                        'date': '2021-02-10 12:00:00',
                        'picture': this.reviewImageEn5,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Juliet Taylor',
                        'text_short': 'This was my first ever escape room (real life or online), and so I spent time researching which online one was best (it was for a birthday surprise event). This ‘Murder on the Underground’ was perfect! 3 screens (5 of us). It’s independent cursor',
                        'text': 'This was my first ever escape room (real life or online), and so I spent time researching which online one was best (it was for a birthday surprise event). This ‘Murder on the Underground’ was perfect! 3 screens (5 of us). It’s independent cursor so we move around our own screens and when someone finds an object a pop up tells the others- however there IS built in sound and video. We see each other in one onscreen rectangle (whoever speaks it’s their image showing) but it moves seamlessly between people speaking (as good as on Zoom, if not better). It meant we could also all work together to solve the clues - one reads out a code that is somewhere else while someone inputs it etc. Easy communication also meant we could tell each other where we were in the scene. No time limit which was great. It was easy to follow and understand but plenty challenging. We solved it in about 1 hour 20/25 mins (only needed 1 hint) and really enjoyed it the whole time! (Loved the end video of the ‘whodunnit). It was great too that at the end it shows all of us & so we could see each other to chat longer; so we stayed talking (now this was better than Zoom quality). A perfect choice for us! Great value too - one fee is for the whole group (not like some of the pay per person ones - which I wouldn’t do). It was so good I’m going to share this review in a FB group too. Oh and you can message them up to 11pm at night using FB messenger ... but we didn’t need to. We didn’t realise though that the first person to enter the scene with our code has to be the one to ‘start’ the game - as they didn’t realise that we all didn’t have the same ‘start button’ ... again though we chatted through the game & figured it out! Brilliant evening!',
                        'rating': 5,
                        'date': '2021-02-10 12:00:00',
                        'picture': this.reviewImageEn6,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Hope Thomas',
                        'text_short': 'Our youth group has done in person escape rooms! And it was awesome to have such an experience that they could online during these times! Thank you so much! We loved it!',
                        'text': 'Our youth group has done in person escape rooms! And it was awesome to have such an experience that they could online during these times! Thank you so much! We loved it!',
                        'rating': 5,
                        'date': '2021-02-04 22:02:00',
                        'picture': this.reviewImageEn7,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Kirsten Sandland Kroeker',
                        'text_short': 'We loved the interactive platform that let us search rooms at our own pace on different devices, while still working together. It was challenging enough to keep us engaged for over 90 minutes - more than we expected - and the storyline and elements',
                        'text': 'We loved the interactive platform that let us search rooms at our own pace on different devices, while still working together. It was challenging enough to keep us engaged for over 90 minutes - more than we expected - and the storyline and elements were top notch! It easily competed with some of the best in-person escape rooms we\'d done!<br /><br />We liked the integration with video chat that kept us connected. Our ONLY complaint was that half the screen was cut off for one of the player\'s devices, and we weren\'t able to solve it with normal screen resolution fixes. One player took screen shots of their screen to send their way to compensate, and for 80% of the time it was no problem, but there was one room where they couldn\'t even see that there was a lock, much less what was in it.',
                        'rating': 5,
                        'date': '2021-01-03 19:10:00',
                        'picture': this.reviewImageEn8,
                        'platform': 'facebook',
                    },
                    {
                        'name': 'Neal Jones',
                        'text_short': 'Played with 6 people together, tons of fun, just as hard as a real room',
                        'text': 'Played with 6 people together, tons of fun, just as hard as a real room',
                        'rating': 5,
                        'date': '2021-01-02 06:52:00',
                        'picture': this.reviewImageEn9,
                        'platform': 'facebook',
                    },
                ];
            }
        }
    },
    methods: {
        platformIcon(platform) {
            switch (platform) {
                case 'facebook':
                    return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">\n' +
                        '  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>\n' +
                        '</svg>';
                    break;
                case 'google':
                    return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">\n' +
                        '  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>\n' +
                        '</svg>';
                    break;
            }
        },
        starsRating(stars) {
            let rating = '';
            for(let i = 0; i < stars; i ++) {
                rating += '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">\n' +
                    '  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>\n' +
                    '</svg>';
            }

            return rating;
        },
        showReview(reviewIndex) {
            this.activeReview = reviewIndex;
            this.showReviewModal = true;
        },
        hideReviewModal() {
            this.showReviewModal = false;
        }
    }
}
</script>

<style scoped>

</style>
