<template>
    <div class="row justify-content-left py-2">
        <div
            class="col-12 col-sm-6 col-lg-4"
            v-for="(room, index) in rooms"
            v-if="!redeemRoom"
        >
            <div class="btn-ve btn-ve-primary text-center my-2" @click="pickRoom(room)">
                {{ room.name }}
             </div>
        </div>
        <div
            class="text-center col-12"
            v-if="redeemRoom"
        >
            {{ this.__('general.use-voucher.confirm') }} <strong>{{ redeemRoom.name }}</strong>. {{ this.__('general.use-voucher.question') }}
            <div class="row justify-content-center mt-4">
                <div class="col-3 btn-ve btn-ve-primary mx-4" @click="confirmRedeem">
                    <div v-if="confirmed"><b-spinner small label="Loading..."></b-spinner></div>
                    <div v-if="!confirmed">{{ this.__('general.confirm') }}</div>
                </div>
                <div class="col-3 btn-ve btn-ve-secondary mx-4" :class="{'btn-ve-disabled': confirmed}" @click="cancelRedeem">
                    {{ this.__('general.cancel') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RedeemVoucherForRoom",
    props: ['rooms', 'voucher'],
    data() {
        return {
            redeemRoom: null,
            confirmed: false
        }
    },
    methods: {
        pickRoom(room) {
            this.redeemRoom = room;
        },
        confirmRedeem() {
            this.confirmed = true;

            axios.post('/redeem-voucher', {
                room: this.redeemRoom,
                voucher: this.voucher
            })
            .then(response => {
                if(response.data.returnUrl) {
                    window.location.href = response.data.returnUrl;
                }
            });
        },
        cancelRedeem() {
            if(!this.confirmed) {
                this.redeemRoom = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
