import { render, staticRenderFns } from "./CarouselCard.vue?vue&type=template&id=40b0c75e&scoped=true"
import script from "./CarouselCard.vue?vue&type=script&lang=js"
export * from "./CarouselCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b0c75e",
  null
  
)

export default component.exports